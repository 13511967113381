import React from 'react';

const StatisticsTable = ({ stats, selectedEmployees }) => {
    return (
        <div className="mt-8">
            <h3 className="text-xl font-semibold mb-4">Statistics</h3>
            <table className="w-full border-collapse border">
                <thead>
                    <tr>
                        <th className="bg-gray-100 p-2 border">Employee</th>
                        <th className="bg-gray-100 p-2 border">Location</th>
                        <th className="bg-gray-100 p-2 border">Total Days</th>
                        <th className="bg-gray-100 p-2 border">Avg Days/Week</th>
                        <th className="bg-gray-100 p-2 border">Total Hours</th>
                        <th className="bg-gray-100 p-2 border">Avg Hours/Day</th>
                        <th className="bg-gray-100 p-2 border">Sat Days</th>
                        <th className="bg-gray-100 p-2 border">Sun Days</th>
                        <th className="bg-gray-100 p-2 border">Avg Sat Duration</th>
                        <th className="bg-gray-100 p-2 border">Avg Sun Duration</th>
                        <th className="bg-gray-100 p-2 border">Total Year Days</th>
                        <th className="bg-gray-100 p-2 border">Total Year Duration</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(stats).filter(([employee]) => selectedEmployees.includes(employee)).map(([employee, { totalDays, avgDaysPerWeek, totalHours, avgHoursPerDay, satDays, sunDays, satDuration, sunDuration, location, yearlyDays, yearlyDuration }]) => (
                        <tr key={employee}>
                            <td className="p-2 border">{employee}</td>
                            <td className="p-2 border">{location}</td>
                            <td className="p-2 border text-center">{totalDays} day(s)</td>
                            <td className="p-2 border text-center">{avgDaysPerWeek.toFixed(2)} day(s)</td>
                            <td className="p-2 border text-center">{totalHours} hour(s)</td>
                            <td className="p-2 border text-center">{avgHoursPerDay.toFixed(1)} hour(s)</td>
                            <td className="p-2 border text-center">{satDays} day(s)</td>
                            <td className="p-2 border text-center">{sunDays} day(s)</td>
                            <td className="p-2 border text-center">{satDuration} min</td>
                            <td className="p-2 border text-center">{sunDuration} min</td>
                            <td className="p-2 border text-center">{yearlyDays} day(s)</td>
                            <td className="p-2 border text-center">{yearlyDuration} hours</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default StatisticsTable;