import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Legend, ResponsiveContainer, LabelList } from 'recharts';
import { sortDates } from '../utils/helpers.js';

const CustomBarChart = ({ data, axisVisibility, xAxisDataKey, selectedEmployees, stats, chartType }) => {
    // Sort the data if it's a monthly chart
    const sortedData = chartType === 'monthly' 
    ? sortDates(data.map(item => item.month), 'Month').map(month => data.find(item => item.month === month))
    : data;

    return (
        <ResponsiveContainer width="100%" height={400} className="mb-6">
            <BarChart data={sortedData} margin={{ top: 50, right: 20, left: 20, bottom: 10 }}>
                <XAxis dataKey={xAxisDataKey} tickLine={true} axisLine={true} allowDecimals={false} />
                {axisVisibility.days && <YAxis yAxisId="left" label={{ value: 'Days', angle: -90, position: 'insideLeft' }} />}
                {axisVisibility.hours && <YAxis yAxisId="right" orientation="right" label={{ value: 'Hours', angle: 90, position: 'insideRight' }} />}
                <Legend />
                {Object.keys(stats).filter((employee) => selectedEmployees.includes(employee)).flatMap((employee) => [
                    axisVisibility.days && (
                    <Bar key={`${employee}-days`} 
                         dataKey={chartType === 'location' ? 'days' : `${employee}.days`} 
                         fill={`hsl(${Object.keys(stats).indexOf(employee) * 137.5}, 50%, 50%)`} 
                         yAxisId="left" 
                         name={`${employee} (Days)`}>
                        <LabelList dataKey={chartType === 'location' ? 'days' : `${employee}.days`} position="top" />
                    </Bar>
                    ),
                    axisVisibility.hours && (
                    <Bar key={`${employee}-duration`} 
                         dataKey={chartType === 'location' ? 'duration' : `${employee}.duration`} 
                         fill={`hsl(${Object.keys(stats).indexOf(employee) * 137.5}, 70%, 70%)`} 
                         yAxisId="right" 
                         name={`${employee} (Hours)`}>
                        <LabelList dataKey={chartType === 'location' ? 'duration' : `${employee}.duration`} position="top" />
                    </Bar>
                    ),
                ])}
            </BarChart>
        </ResponsiveContainer>
    );
};

export default CustomBarChart;