import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CheckBadgeIcon, NoSymbolIcon } from '@heroicons/react/24/solid';
import ImageGallery from '../../components/ImageGallery';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

interface Question {
  id: number;
  section_id: number;
  text: string;
  order_index: number;
  answer: boolean;
  comment: string | null;
  image_url: string | null;
}

interface Section {
  id: number;
  name: string;
  order_index: number;
  questions: Question[];
  image_urls: string[];
  score: number;
}

interface AuditData {
  audit: {
    id: number;
    template_id: number;
    area_id: number;
    location_id: number;
    user_id: string;
    date: string;
    overall_comment: string;
    score: string;
    area_name: string;
    location_name: string;
    quarter: number;
  };
  sections: Section[];
}

const AuditDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [auditData, setAuditData] = useState<AuditData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showAllQuestions, setShowAllQuestions] = useState(false);

  useEffect(() => {
    const fetchAuditDetails = async () => {
      try {
        const response = await axios.get(`/api/auditDetails/${id}`);
        setAuditData(response.data);
        console.log('Audit Data:', response.data);  // For debugging
      } catch (error) {
        console.error('Error fetching audit details:', error);
        setError('Failed to fetch audit details. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchAuditDetails();
  }, [id]);

  const formatScore = (score: string): string => {
    const parsedScore = parseFloat(score);
    return isNaN(parsedScore) ? 'Invalid Score' : `${parsedScore.toFixed(2)}%`;
  };

  const filterSectionsAndQuestions = (sections: Section[]): Section[] => {
    if (showAllQuestions) return sections;
  
    return sections.filter(section => {
      const hasRelevantQuestions = section.questions.some(question => 
        !question.answer || question.comment
      );
      const hasImages = section.image_urls && section.image_urls.length > 0;
  
      return hasRelevantQuestions || hasImages;
    }).map(section => ({
      ...section,
      questions: section.questions.filter(question => 
        !question.answer || question.comment
      )
    }));
  };


  if (error) return <div>Error: {error}</div>;
  if (!auditData) return <div>No audit details found</div>;

  const { audit, sections } = auditData;

  return (
    <div className="p-4 md:p-16">
      <div className="pb-8 mb-4 max-w-3xl">
        <div className="bg-gray-100 mb-4 rounded-lg p-4 grid grid-cols-2 gap-4">
          <div>
            <p className="font-bold">Date:</p>
            <p>Q{audit.quarter} - {new Date(audit.date).toLocaleDateString('en-GB', { day: '2-digit', month: 'long', year: 'numeric' })}</p>
          </div>

          <div>
            <p className="font-bold">Location:</p>
            <p>{audit.area_name} - {audit.location_name}</p>
          </div>
          <div>
            <p className="font-bold">Score:</p>
            <p>{formatScore(audit.score)}</p>
          </div>
          <div>
          <p className="font-bold">Comment:</p>
          <p>{audit.overall_comment || 'No comment provided'}</p>
        </div>
        </div>
        <div className="mb-4">
          <button
            onClick={() => setShowAllQuestions(!showAllQuestions)}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            {showAllQuestions ? "Show Only 'No' Answers and Comments" : "Show All Questions"}
          </button>
        </div>
        
        {filterSectionsAndQuestions(sections).map((section) => (
        <div key={section.id} className="mb-6 bg-gray-100 rounded-lg p-4">
        <div className="flex justify-between items-center mb-2">
          <h2 className="text-xl font-bold">{section.name}</h2>
          <span className="bg-blue-500 text-white whitespace-nowrap text-sm font-semibold px-2.5 py-0.5 rounded-full self-start">
            {section.score} %
          </span>
        </div>
        {section.questions.map((question) => (
          <div key={question.id} className="mb-4 p-2 bg-white rounded"> 
            <p className="flex items-center gap-2 lg:text-lg">
              {question.answer ? 
                <CheckBadgeIcon className='h-6 w-6 text-green-500 flex-shrink-0' /> : 
                <NoSymbolIcon className='h-6 w-6 text-red-500 flex-shrink-0' />
              } 
              {question.text}
            </p> 
            {question.comment && <p>{question.comment}</p>}
          </div>
        ))}
        {section.image_urls && section.image_urls.length > 0 && (
            <ImageGallery images={section.image_urls} />
          )}
      </div>
      ))}
    </div>
    <button
      onClick={() => navigate('/audit/history')}
      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
    >
      Back to Audit History
    </button>
  </div>
  );
};

export default AuditDetails;