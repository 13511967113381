import React from 'react';

const LocationSelect = ({ locations, selectedLocations, handleLocationChange }) => {
  return (
    <select
      multiple
      value={selectedLocations}
      onChange={handleLocationChange}
      className="p-2 border border-gray-300 rounded w-96"
    >
      {locations.map((location) => (
        <option key={location} value={location}>
          {location}
        </option>
      ))}
    </select>
  );
};

export default LocationSelect;