import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

interface ImageGalleryProps {
  images: string[];
}

const ImageGallery: React.FC<ImageGalleryProps> = ({ images }) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const openImage = (image: string) => {
    setSelectedImage(image);
  };

  const closeImage = () => {
    setSelectedImage(null);
  };

  const createThumbnailUrl = (url: string) => {
    // This function should create a thumbnail URL. 
    // For now, we'll just return the original URL.
    // In a production environment, you'd want to generate actual thumbnails.
    return url;
  };

  return (
    <div>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {images.map((image, index) => (
          <LazyLoadImage
            key={index}
            src={createThumbnailUrl(image)}
            alt={`Thumbnail ${index + 1}`}
            className="w-full h-32 object-cover cursor-pointer rounded-lg"
            onClick={() => openImage(image)}
          />
        ))}
      </div>

      {selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50" onClick={closeImage}>
          <div className="max-w-3xl max-h-full p-4">
            <LazyLoadImage
              src={selectedImage}
              alt="Full size"
              className="max-w-full max-h-full object-contain"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageGallery;