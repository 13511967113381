import React from 'react';
import { sortDates } from '../utils/helpers.js';

const TimeSlotAttendance = ({ data, selectedEmployees, stats }) => {
    // Get and sort all unique dates
    const allDates = sortDates([...new Set(Object.keys(data.timeSlotData))]);
    
    return (
        <div className="mt-8">
            <h3 className="text-xl font-semibold mb-4">Time Slot Attendance</h3>
            <div className="overflow-x-auto custom-scroll">
                <table className="w-full border-collapse border">
                <tbody>
                    {['5-9', '9-12', '12-15', '15-18', '18-21', '21-24']
                        .filter(timeSlot => 
                            allDates.some(date => 
                                data.timeSlotData[date] && 
                                data.timeSlotData[date][timeSlot] && 
                                Object.keys(data.timeSlotData[date][timeSlot]).some(employee => 
                                    selectedEmployees.includes(employee)
                                )
                            )
                        )
                        .map(timeSlot => (
                        <tr key={timeSlot}>
                            <td className="p-2 border text-center text-xs w-auto whitespace-nowrap">{timeSlot}</td>
                            {allDates.map(date => (
                                    <td
                                        key={date}
                                        title={date}
                                        className={`p-2 border text-center ${data.timeSlotData[date] && data.timeSlotData[date][timeSlot] && Object.keys(data.timeSlotData[date][timeSlot]).some(employee => selectedEmployees.includes(employee)) ? 'bg-gray-100' : ''}`}
                                        style={{ verticalAlign: 'middle' }}
                                    >
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            {data.timeSlotData[date] && data.timeSlotData[date][timeSlot] &&
                                                Object.keys(data.timeSlotData[date][timeSlot])
                                                    .filter(employee => selectedEmployees.includes(employee))
                                                    .map(employee => (
                                                        <span
                                                            key={employee}
                                                            style={{
                                                                backgroundColor: `hsl(${Object.keys(stats).indexOf(employee) * 137.5}, 70%, 50%)`,
                                                                width: '5px',
                                                                height: '5px',
                                                                display: 'block',
                                                                borderRadius: '50%',
                                                                marginBottom: '2px'
                                                            }}
                                                        ></span>
                                                    ))}
                                        </div>
                                    </td>
                                ))}
                        </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div>
            {Object.keys(stats).filter((employee) => selectedEmployees.includes(employee)).map((employee, index) => (
                <span key={employee} style={{ display: 'inline-block', marginRight: '10px' }}>
                <span style={{ backgroundColor: `hsl(${Object.keys(stats).indexOf(employee) * 137.5}, 70%, 50%)`, width: '10px', height: '10px', display: 'inline-block', borderRadius: '50%', marginRight: '5px' }}></span>
                {employee}
                </span>
            ))}
            </div>
        </div>
    );
};

export default TimeSlotAttendance;