import React, { useState } from 'react';
import '../styles/index.css';
import Report1 from './Report1.js';
import Report2 from './Report2.js';
import Report3 from './Report3.js';

const EmployeeAccessDashboard = ({ report, isDarkMode }) => {
    const [report1Data, setReport1Data] = useState({ chartWeeklyData: [], chartMonthlyData: [], chartLocationData: [], stats: {}, timeSlotData: {}, rawData: [] });
    const [report2Data, setReport2Data] = useState({ rawData: [], processedData: {} });
    const [report3Data, setReport3Data] = useState({ salesData: [], areas: [], locations: [], months: [], locationSummary: [], areaSummary: [] });
    const [axisVisibility, setAxisVisibility] = useState({ days: true, hours: true });

    const handleToggleAxis = (axis) => {
        setAxisVisibility((prevVisibility) => ({ ...prevVisibility, [axis]: !prevVisibility[axis] }));
    };

    return (
        <div>
            <div className="fle flex-1 flex-col">
                {report === "1" && (
                    <Report1 
                        data={report1Data} 
                        axisVisibility={axisVisibility} 
                        handleToggleAxis={handleToggleAxis} 
                        setData={setReport1Data}
                    />
                )}
                {report === "2" && (
                    <Report2 
                        data={report2Data}
                        setData={setReport2Data}
                    />
                )}
                {report === "3" && (
                    <Report3 
                        data={report3Data}
                        setData={setReport3Data}
                    />
                )}
            </div>
        </div>
    );
};

export default EmployeeAccessDashboard;