import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend } from 'recharts';
import { getUnifiedColor } from '../utils/helpers.js';

const IncomeDistribution = ({ incomeBrackets, selectedTime, years }) => {
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const RADIAN = Math.PI / 180;
        const radius = outerRadius + 40;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
    
        return (
            <text
                x={x}
                y={y}
                fill="black"
                textAnchor={x > cx ? 'start' : 'end'}
                dominantBaseline="central"
            >
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    const chartData = useMemo(() => {
        //console.log('IncomeDistribution - Processing chart data');
        if (selectedTime === 'all') {
            // For all years, create separate data for each year
            const data = years.reduce((acc, year) => {
                acc[year] = incomeBrackets
                    .filter(item => item.year === year)
                    .map(item => ({
                        bracket: item.bracket,
                        count: item.count
                    }));
                return acc;
            }, {});
            //console.log('IncomeDistribution - All years chart data:', data);
            return data;
        } else {
            // For a specific year or month, create pie chart data
            const data = incomeBrackets.filter(item => item.count > 0);
            //console.log('IncomeDistribution - Single year/month chart data:', data.slice(0, 3));
            return data;
        }
    }, [incomeBrackets, selectedTime, years]);

    //onsole.log('IncomeDistribution - Processed chart data:', chartData);
    
    if (Object.keys(chartData).length === 0) {
        //console.log('IncomeDistribution - No chart data available');
        return <div>No income distribution data available for the selected time period.</div>;
    }

    const renderChart = () => {
        if (selectedTime === 'all') {
            // Render separate pie charts for each year
            return (
                <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-2 gap-4">
                    {years.map(year => (
                        <div key={year} className="mb-8">
                            <h3 className="text-lg font-semibold mb-2 text-center">{year}</h3>
                            <ResponsiveContainer width="100%" height={500}>
                                <PieChart>
                                    <Pie
                                        data={chartData[year]}
                                        dataKey="count"
                                        nameKey="bracket"
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={180}
                                        fill="#8884d8"
                                        labelLine={true}
                                        label={renderCustomizedLabel}
                                    >
                                        {chartData[year].map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={getUnifiedColor(entry.bracket)} />
                                        ))}
                                    </Pie>
                                    <Tooltip 
                                        formatter={(value, name, props) => {
                                            const total = chartData[year].reduce((sum, entry) => sum + entry.count, 0);
                                            const percentage = ((value / total) * 100).toFixed(2);
                                            return [`${value} (${percentage}%)`, name];
                                        }} 
                                    />
                                    <Legend />
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                    ))}
                </div>
            );
        } else {
            // Render pie chart for specific year or month
            return (                
                <ResponsiveContainer width="100%" height={400}>
                    <PieChart>
                        <Pie
                            data={chartData}
                            dataKey="count"
                            nameKey="bracket"
                            cx="50%"
                            cy="50%"
                            outerRadius={150}
                            fill="#8884d8"
                            labelLine={true}
                            label={renderCustomizedLabel}
                        >
                            {chartData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={getUnifiedColor(entry.bracket)} />
                            ))}
                        </Pie>
                        <Tooltip 
                            formatter={(value, name, props) => {
                                const total = chartData.reduce((sum, entry) => sum + entry.count, 0);
                                const percentage = ((value / total) * 100).toFixed(2);
                                return [`${value} (${percentage}%)`, name];
                            }} 
                        />
                        <Legend />
                    </PieChart>
                </ResponsiveContainer>
            );
        }
    };

    return (
        <div className="mb-8">
            <h2 className="text-xl font-bold mb-4">Income Distribution</h2>
            {renderChart()}
        </div>
    );
};

IncomeDistribution.propTypes = {
    incomeBrackets: PropTypes.arrayOf(PropTypes.shape({
        year: PropTypes.string.isRequired,
        bracket: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired
    })).isRequired,
    selectedTime: PropTypes.string.isRequired,
    years: PropTypes.arrayOf(PropTypes.string).isRequired
};

IncomeDistribution.defaultProps = {
    incomeBrackets: [],
    years: []
};

export default IncomeDistribution;