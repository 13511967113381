import React from 'react';
import { calculateMissingDays } from '../utils/helpers.js';

const MissingDaysTable = ({ data, selectedLocations, selectedEmployees }) => {
    const missingDays = React.useMemo(() => 
        selectedEmployees.length > 0 ? calculateMissingDays(data, selectedLocations, selectedEmployees) : [],
        [data, selectedLocations, selectedEmployees]
    );

    if (selectedEmployees.length === 0) {
        return null;
    }

    if (missingDays.length === 0) {
        return <p>No missing days found for the selected locations and employees.</p>;
    }

    return (
        <div className="mt-8">
            <h4 className="pb-4 text-xl font-bold">Missing Days</h4>
            <div className="overflow-x-auto custom-scroll">
                <table className="table-auto  text-left">
                    <thead>
                        <tr className='pr-16 border-b border-gray-400'>
                            <th className="auto whitespace-nowrap">Location</th>
                            <th>Week</th>
                            <th>Day</th>
                        </tr>
                    </thead>
                    <tbody>
                        {missingDays.map((day, index) => (
                            <tr key={index}>
                                <td className="pr-8 pt-2 auto whitespace-nowrap">{day.location}</td>
                                <td className="pr-16 pt-2 w-auto whitespace-nowrap text-center">{day.week}</td>
                                <td className="pt-2 w-auto whitespace-nowrap">{day.day}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default MissingDaysTable;