import React from 'react';
import PropTypes from 'prop-types';
import { ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Line } from 'recharts';
import { getUnifiedColor, formatNumber } from '../utils/helpers.js';

const YearlyIncomeChart = ({ data, selectedTime, years }) => {
    //console.log('YearlyIncomeChart - Props:', { data, selectedTime, years });
    const isMultipleYears = selectedTime === 'all';
    const isSingleYear = selectedTime.length === 4;

    if (!isMultipleYears && !isSingleYear) {
        return null;
    }

    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const processChartData = () => {
        if (isMultipleYears) {
            return years.map(year => {
                let cumulativeIncome = 0;
                return {
                    year,
                    ...months.reduce((acc, month) => {
                        const monthlyIncome = data[year]?.months[month] || 0;
                        cumulativeIncome += monthlyIncome;
                        acc[month] = monthlyIncome;
                        acc[`cumulative${month}`] = cumulativeIncome;
                        return acc;
                    }, {}),
                    totalYearIncome: data[year]?.total || 0
                };
            });
        } else {
            let cumulativeIncome = 0;
            return months.map(month => {
                const monthlyIncome = data[selectedTime]?.months[month] || 0;
                cumulativeIncome += monthlyIncome;
                return {
                    month,
                    income: monthlyIncome,
                    cumulativeIncome: cumulativeIncome,
                    totalYearIncome: data[selectedTime]?.total || 0
                };
            });
        }
    };

    const chartData = processChartData();
    //console.log('YearlyIncomeChart - Processed chart data:', chartData);

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip bg-white p-2 border border-gray-300 rounded shadow">
                    <p className="label font-bold">{label}</p>
                    {isMultipleYears ? (
                        <>
                            {months.map((month) => (
                                <p key={month}>
                                    {`${month}: kr ${formatNumber(payload[0].payload[month] || 0)}`}
                                </p>
                            ))}
                            <p className='font-bold'>
                                {`Cumulative Income: kr ${formatNumber(payload[0].payload[`cumulative${months[months.length - 1]}`])}`}
                            </p>
                        </>
                    ) : (
                        <>
                            <p>{`Monthly Income: kr ${formatNumber(payload[0].payload.income)}`}</p>
                            <p className='font-bold'>
                                {`Yearly Income: kr ${formatNumber(payload[0].payload.cumulativeIncome)}`}
                            </p>
                        </>
                    )}
                </div>
            );
        }
        return null;
    };

    const chartTitle = isMultipleYears
        ? "Yearly Income by Month"
        : `Monthly Income for ${selectedTime}`;

    return (
        <div className="w-full h-96">
            <h2 className="text-xl font-bold mb-4 text-center">{chartTitle}</h2>
            <ResponsiveContainer width="100%" height="100%">
                <ComposedChart data={chartData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="year" />
                    <YAxis 
                        yAxisId="left"
                        label={{ value: 'Monthly Income', angle: -90, position: 'insideLeft' }}
                        tickFormatter={(value) => (value / 1000000).toFixed(2) + 'M'}
                        width={80}
                    />
                    <YAxis 
                        yAxisId="right"
                        orientation="right"
                        label={{ value: 'Cumulative Income', angle: 90, position: 'insideRight' }}
                        tickFormatter={(value) => (value / 1000000).toFixed(2) + 'M'}
                        width={100}
                    />
                    <Tooltip content={<CustomTooltip />} offset={200}/>
                    <Legend />
                    {isMultipleYears ? (
                        months.map(month => (
                            <Bar 
                                key={month}
                                dataKey={month}
                                fill={getUnifiedColor(month)}
                                name={`${month}`}
                                yAxisId="left"
                            />
                        ))
                    ) : (
                        <Bar dataKey="income" fill={getUnifiedColor('income')} name="Monthly Income" yAxisId="left" />
                    )}
                    <Line 
    type="linear" 
    dataKey={isMultipleYears ? `cumulative${months[months.length - 1]}` : "cumulativeIncome"}
    stroke="#ff7300" 
    name="Cumulative Income"
    yAxisId="right"
/>
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    );
};

YearlyIncomeChart.propTypes = {
    data: PropTypes.object.isRequired,
    selectedTime: PropTypes.string.isRequired,
    years: PropTypes.arrayOf(PropTypes.string).isRequired
};



export default YearlyIncomeChart;