import React from 'react';

const AxisToggleButtons = ({ axisVisibility, handleToggleAxis }) => {
    return (
        <div className="mb-6 space-x-4">
            <button 
                className={`text-white py-2 px-4 rounded ${axisVisibility.days ? 'font-bold bg-blue-500 hover:bg-blue-700' : 'text-black bg-gray-300 hover:bg-gray-500'}`} 
                onClick={() => handleToggleAxis('days')}>
                {axisVisibility.days ? 'Hide' : 'Show'} Days
            </button>
            <button 
                className={`text-white py-2 px-4 rounded ${axisVisibility.hours ? 'font-bold bg-blue-500 hover:bg-blue-700' : 'text-black bg-gray-300 hover:bg-gray-500'}`} 
                onClick={() => handleToggleAxis('hours')}>
                {axisVisibility.hours ? 'Hide' : 'Show'} Hours
            </button>
        </div>
    );
};

export default AxisToggleButtons;