export const saveToLocalStorage = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
};

export const loadFromLocalStorage = (key) => {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : [];
};

export const handleSaveData = (selectedData, key) => {
    if (!Array.isArray(selectedData)) {
        throw new Error('selectedData should be an array');
    }

    const existingData = loadFromLocalStorage(key);

    const updatedData = selectedData.map(newData => {
        const existingEntry = existingData.find(item => item.label === newData.label);
        if (existingEntry) {
            return {
                ...existingEntry,
                data: [...new Set([...existingEntry.data, ...newData.data])],
                weeks: [...new Set([...existingEntry.weeks, ...newData.weeks])],
            };
        }
        return newData;
    });

    const mergedData = [
        ...updatedData,
        ...existingData.filter(item => !selectedData.some(newData => newData.label === item.label)),
    ];

    saveToLocalStorage(key, mergedData);
};

export const getWeekNumberISO8601 = (date) => {
    const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
};

export const handleSaveClick = (title, selectedLocations, averages, savedDataTitles, setSavedDataTitles) => {
    if (!title) {
        alert('Please provide a title for the dataset.');
        return;
    }

    const selectedData = selectedLocations.map(location => ({
        label: location,
        data: Object.values(averages[location] || {}),
        backgroundColor: getUnifiedColor(location),
        weeks: Object.keys(averages[location] || {}).map(week => `Week ${week}`)
    }));

    const existingTitles = savedDataTitles.includes(title) ? savedDataTitles : [...savedDataTitles, title];
    setSavedDataTitles(existingTitles);
    saveToLocalStorage('saved_data_titles', existingTitles);

    const saveKey = `consolidated_data_${title}`;
    handleSaveData(selectedData, saveKey);
    return loadFromLocalStorage(saveKey) || [];
};

export const handleLoadData = (title) => {
    const saveKey = `consolidated_data_${title}`;
    return loadFromLocalStorage(saveKey) || [];
};

export const handleDeleteData = (title, savedDataTitles, setSavedDataTitles, currentDataTitle, setCurrentDataTitle, setConsolidatedData) => {
    const saveKey = `consolidated_data_${title}`;
    localStorage.removeItem(saveKey);

    const updatedTitles = savedDataTitles.filter(item => item !== title);
    setSavedDataTitles(updatedTitles);
    saveToLocalStorage('saved_data_titles', updatedTitles);

    if (currentDataTitle === title) {
        setCurrentDataTitle('');
        setConsolidatedData([]);
    }
};

export const calculateAverages = (data, selectedLocations, selectedEmployees) => {
    if (!data || !data.locationStats) return {};

    const averages = {};
    selectedLocations.forEach(location => {
        const locationData = data.locationStats[location];
        if (!locationData) return;

        averages[location] = {};
        Object.entries(locationData).forEach(([week, weekData]) => {
            let totalDuration = 0;
            let totalDays = 0;

            Object.values(weekData).forEach(dayData => {
                selectedEmployees.forEach(employee => {
                    if (dayData[employee]) {
                        totalDuration += dayData[employee].adjustedDuration;
                        totalDays++;
                    }
                });
            });

            const average = totalDays > 0 ? Math.round((totalDuration / totalDays) * 10) / 10 : 0;
            averages[location][week] = average;
        });
    });

    return averages;
};

export const calculateMissingDays = (data, selectedLocations, selectedEmployees) => {
    if (!data || !data.locations || !data.dateRange) return [];

    const missingDays = [];
    const startDate = new Date(data.dateRange.start);
    const endDate = new Date(data.dateRange.end);

    for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
        const dayOfWeek = d.getUTCDay();
        if (dayOfWeek >= 1 && dayOfWeek <= 6) { 
            const dayKey = d.toISOString().split('T')[0];
            selectedLocations.forEach(location => {
                if (data.locations[location] && (!data.locations[location][dayKey] || 
                    !selectedEmployees.some(emp => data.locations[location][dayKey].includes(emp)))) {
                    missingDays.push({
                        location,
                        day: dayKey,
                        week: getWeekNumberISO8601(d)
                    });
                }
            });
        }
    }

    return missingDays;
};

export const sortDates = (dates, format = 'DD/MM') => {
    return dates.sort((a, b) => {
      if (format === 'DD/MM') {
        const [dayA, monthA] = a.split('/');
        const [dayB, monthB] = b.split('/');
        return monthA - monthB || dayA - dayB;
      } else if (format === 'Month') {
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 
                        'July', 'August', 'September', 'October', 'November', 'December'];
        return months.indexOf(a) - months.indexOf(b);
      }
      return 0;
    });
  };

export const getUnifiedColor2 = (input) => {
    const yearColors = [
        '#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8',
        '#F06292', '#AED581', '#7E57C2', '#FFD54F', '#4DB6AC',
        '#9575CD', '#4FC3F7', '#81C784', '#FF8A65', '#A1887F',
        '#9FA8DA', '#FFF176', '#FFB74D', '#BA68C8', '#4DD0E1'
    ];
    
    const stringToHSL = (str) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        const scaledHash = hash * 31;
        const hue = (scaledHash % 360 + 360) % 360;
        const saturation = 70 + (hash % 30);
        const lightness = 45 + (hash % 20);
        return [hue, saturation, lightness];
    };

    const hslToHex = (h, s, l) => {
        l /= 100;
        const a = s * Math.min(l, 1 - l) / 100;
        const f = n => {
            const k = (n + h / 30) % 12;
            const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
            return Math.round(255 * color).toString(16).padStart(2, '0');
        };
        return `#${f(0)}${f(8)}${f(4)}`;
    };

    if (typeof input === 'string' && input.length === 4 && !isNaN(input)) {
        const yearIndex = parseInt(input.slice(2)) % yearColors.length;
        return yearColors[yearIndex];
    } else {
        const [hue, saturation, lightness] = stringToHSL(input.toString());
        return hslToHex(hue, saturation, lightness);
    }
};
export const getUnifiedColor = (input, baseColor = [200, 70, 50]) => {
    const [baseHue, baseSaturation, baseLightness] = baseColor;
    //console.log('getUnifiedColor - Input:', input);

    const stringToColorShift = (str) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        const scaledHash = hash * 19;
        const hueShift = (scaledHash % 60 + 60) % 60 - 30; // -30 to +30 degree shift
        const saturationShift = (scaledHash % 40 + 40) % 40 - 20; // -20 to +20 percent shift
        const lightnessShift = (scaledHash % 30 + 30) % 30 - 15; // -15 to +15 percent shift
        return [hueShift, saturationShift, lightnessShift];
    };
    const hslToHex = (h, s, l) => {
        l /= 100;
        const a = s * Math.min(l, 1 - l) / 100;
        const f = n => {
            const k = (n + h / 30) % 12;
            const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
            return Math.round(255 * color).toString(16).padStart(2, '0');
        };
        return `#${f(0)}${f(8)}${f(4)}`;
    };

    const [hueShift, saturationShift, lightnessShift] = stringToColorShift(input.toString());
    //console.log('getUnifiedColor - Shifted colors:', [hueShift, saturationShift, lightnessShift]);

    const newHue = (baseHue + hueShift + 360) % 360;
    const newSaturation = Math.max(0, Math.min(100, baseSaturation + saturationShift));
    const newLightness = Math.max(0, Math.min(100, baseLightness + lightnessShift));

    return hslToHex(newHue, newSaturation, newLightness);
};

export const formatNumber = (number) => {
    if (number === undefined || number === null || isNaN(number)) {
        return '0,00';
    }
    const parts = Number(number).toFixed(2).split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join(',');
};

export const calculateSummaryData = (filteredData, selectedTime, years) => {
    /*console.log('calculateSummaryData - Input:', { 
        filteredDataLength: filteredData.length,
        filteredDataSample: filteredData,
        selectedTime, 
        years 
    });*/
    //console.log('filteredData:', filteredData);

    if (!filteredData.length) {
        //console.log('calculateSummaryData - No filtered data');
        return null;
    }

    const isAllYears = selectedTime === 'all';
    const isSingleYear = selectedTime.length === 4;
    const isSingleMonth = selectedTime.length > 4;

    let result;

    if (isAllYears) {
        const yearlyData = {};
        const allYearsPTRevenues = {}; // Track PT revenues across all years
    
        years.forEach(year => {
            const yearData = {
                totalSales: 0,
                activePTs: 0,
                totalHours: 0,
                topPT: { full_name: '', total_revenue: 0 },
                topLocation: { location: '', revenue: 0 },
                topArea: { area: '', revenue: 0 },
                locationRevenue: {},
                areaRevenue: {},
            };
    
            // Filter data for this specific year
            const yearFilteredData = filteredData.filter(item => item.year === year);
    
            // Count unique PTs for this year
            //yearData.activePTs = new Set(yearFilteredData.map(item => item.id)).size;
            yearData.activePTs = new Set(yearFilteredData.filter(item => item.total_revenue > 0).map(item => item.id)).size;
    
            const ptRevenues = {}; // Reset for each year
            const ptNames = {}; // Reset for each year
    
            yearFilteredData.forEach(pt => {
                const yearRevenue = pt.total_revenue;
                yearData.totalSales += yearRevenue;
                yearData.totalHours += pt.total_hours;
    
                // Update cumulative revenue for this PT across all years
                allYearsPTRevenues[pt.id] = (allYearsPTRevenues[pt.id] || 0) + yearRevenue;
    
                // Accumulate revenue for each PT for this year only
                ptRevenues[pt.id] = (ptRevenues[pt.id] || 0) + yearRevenue;
                ptNames[pt.id] = pt.full_name;
    
                // Update location and area revenue calculations (unchanged)
                yearData.locationRevenue[pt.location] = (yearData.locationRevenue[pt.location] || 0) + yearRevenue;
                if (yearData.locationRevenue[pt.location] > yearData.topLocation.revenue) {
                    yearData.topLocation = { location: pt.location, revenue: yearData.locationRevenue[pt.location] };
                }
    
                yearData.areaRevenue[pt.area] = (yearData.areaRevenue[pt.area] || 0) + yearRevenue;
                if (yearData.areaRevenue[pt.area] > yearData.topArea.revenue) {
                    yearData.topArea = { area: pt.area, revenue: yearData.areaRevenue[pt.area] };
                }
            });
    
            // Determine top PT based on cumulative revenue for this year only
            const topPT = Object.entries(ptRevenues).reduce((max, [id, revenue]) => 
                revenue > max.total_revenue ? { id, total_revenue: revenue } : max
            , { id: '', total_revenue: 0 });
            
            // Format the topPT data as requested
            yearData.topPT = {
                full_name: ptNames[topPT.id],
                total_revenue: topPT.total_revenue
            };
    
            yearData.avgIncome = yearData.activePTs > 0 ? yearData.totalSales / yearData.activePTs : 0;
            yearData.avgRevenuePerHour = yearData.totalHours > 0 ? yearData.totalSales / yearData.totalHours : 0;
    
            yearlyData[year] = yearData;
        });
    
        // Calculate top PT across all years
        const topPTAllYears = Object.entries(allYearsPTRevenues).reduce((max, [id, revenue]) => 
            revenue > max.total_revenue ? { id, total_revenue: revenue } : max
        , { full_name: '', total_revenue: 0 });
    
        // Get the full name of the top PT
        const topPTFullName = filteredData.find(item => item.id === topPTAllYears.id)?.full_name || '';
    
        // Calculate overall statistics
        const overallStats = years.reduce((acc, year) => {
            acc.totalSales += yearlyData[year].totalSales;
            acc.totalHours += yearlyData[year].totalHours;
            return acc;
        }, { totalSales: 0, totalHours: 0 });
    
        // Use the count of unique active PTs across all years
        //overallStats.activePTs = activePTs;
        //console.log('activePTs:', activePTs);
    
        // Calculate overall average income and average revenue per hour
        overallStats.avgIncome = overallStats.activePTs > 0 ? overallStats.totalSales / overallStats.activePTs : 0;
        overallStats.avgRevenuePerHour = overallStats.totalHours > 0 ? overallStats.totalSales / overallStats.totalHours : 0;
    
        result = { 
            type: 'multi-year', 
            yearlyData, 
            years,
            topPT: { full_name: topPTFullName, total_revenue: topPTAllYears.total_revenue },
            ...overallStats
        };
    } else {
        //const activePT = new Set(filteredData.map(item => item.full_name))
        //console.log('activePTs:', activePT);
        //const activePTs = new Set(filteredData.map(item => item.id)).size;
        const activePTs = new Set(filteredData.filter(item => item.total_revenue > 0).map(item => item.id)).size;
        let totalSales = 0;
        let totalHours = 0;
        const ptRevenues = {}; // New map to store cumulative revenue for each PT
        const locationRevenues = {};
        const areaRevenues = {};

        filteredData.forEach(item => {
            let itemRevenue = item.total_revenue;
            let itemHours = item.total_hours;

            if (isSingleYear && item.year === selectedTime) {
                totalSales += itemRevenue;
                totalHours += itemHours;
            } else if (isSingleMonth) {
                const [year, month] = selectedTime.split('-');
                if (item.year === year && item.month === month) {
                    totalSales += itemRevenue;
                    totalHours += itemHours;
                } else {
                    return; // Skip this item if it doesn't match the selected month
                }
            }

            // Update cumulative revenue for this PT
            ptRevenues[item.id] = (ptRevenues[item.id] || 0) + itemRevenue;

            locationRevenues[item.location] = (locationRevenues[item.location] || 0) + itemRevenue;
            areaRevenues[item.area] = (areaRevenues[item.area] || 0) + itemRevenue;
        });

        // Determine top PT based on cumulative revenue
        const topPT = Object.entries(ptRevenues).reduce((max, [id, revenue]) => 
            revenue > max.total_revenue ? { id, total_revenue: revenue } : max
        , { id: '', total_revenue: 0 });

        // Get the full name of the top PT
        const topPTFullName = filteredData.find(item => item.id === topPT.id)?.full_name || '';

        const topLocation = Object.entries(locationRevenues).reduce((max, [location, revenue]) =>
            revenue > max.revenue ? { location, revenue } : max
        , { location: '', revenue: 0 });

        const topArea = Object.entries(areaRevenues).reduce((max, [area, revenue]) =>
            revenue > max.revenue ? { area, revenue } : max
        , { area: '', revenue: 0 });

        result = {
            type: 'single',
            activePTs,
            totalSales,
            totalHours,
            avgRevenuePerHour: totalHours > 0 ? totalSales / totalHours : 0,
            avgIncome: activePTs > 0 ? totalSales / activePTs : 0,
            topPT: { full_name: topPTFullName, total_revenue: topPT.total_revenue },
            topLocation,
            topArea
        };
    }

    //console.log('calculateSummaryData - Output:', result);
    return result;
};

export const calculateIncomeBrackets = (filteredData, selectedTime, years, calculateTotalRevenue) => {
    //console.log('calculateIncomeBrackets - Input:', { filteredData: filteredData[0], selectedTime, years });

    if (!filteredData.length || !years.length) return [];

    // Aggregate total revenue for each PT
    const ptTotals = filteredData.reduce((acc, item) => {
        const id = item.id; // Assuming each PT has a unique id
        const year = item.year;
        const revenue = calculateTotalRevenue(item);

        if (!acc[year]) acc[year] = {};
        if (!acc[year][id]) acc[year][id] = 0;
        acc[year][id] += revenue;

        return acc;
    }, {});

    const brackets = {};

    // Calculate brackets based on aggregated PT totals
    Object.entries(ptTotals).forEach(([year, ptData]) => {
        brackets[year] = {};
        Object.values(ptData).forEach(totalRevenue => {
            const bracket = Math.floor(totalRevenue / 200000) * 200000;
            brackets[year][bracket] = (brackets[year][bracket] || 0) + 1;
        });
    });

    const result = years.flatMap(year => {
        return Object.entries(brackets[year] || {})
            .map(([bracket, count]) => ({
                year,
                bracket: `${parseInt(bracket) / 1000}k-${(parseInt(bracket) + 200000) / 1000}k`,
                count: count
            }))
            .filter(item => item.count > 0)
            .sort((a, b) => parseInt(a.bracket) - parseInt(b.bracket));
    });

    //console.log('calculateIncomeBrackets - Output:', result);
    return result;
};

