import React from 'react';
import PropTypes from 'prop-types';
import AreaSelect from './AreaSelect.js';
import LocationSelect from './LocationSelect.js';

const FilterControls = ({ 
  areas,
  selectedAreas,
  handleAreaChange,
  filteredLocations,
  selectedLocations,
  handleLocationChange,
  timeOptions,
  selectedTime,
  handleTimeChange
}) => (
  <div className="flex justify-center mb-8 space-x-4">
    <AreaSelect
      areas={areas}
      selectedAreas={selectedAreas}
      handleAreaChange={handleAreaChange}
    />
    <LocationSelect
      locations={filteredLocations}
      selectedLocations={selectedLocations}
      handleLocationChange={handleLocationChange}
    />
    <select onChange={handleTimeChange} value={selectedTime}>
      {timeOptions.map(option => (
        <option key={option.value} value={option.value}>{option.label}</option>
      ))}
    </select>
  </div>
);

FilterControls.propTypes = {
  areas: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedAreas: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleAreaChange: PropTypes.func.isRequired,
  filteredLocations: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedLocations: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleLocationChange: PropTypes.func.isRequired,
  timeOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  })).isRequired,
  selectedTime: PropTypes.string.isRequired,
  handleTimeChange: PropTypes.func.isRequired
};

export default React.memo(FilterControls);