import React, { useState } from 'react';
import * as XLSX from 'xlsx';

const excelDateToJSDate = (serial) => {
  const excelEpoch = new Date(1899, 11, 30);
  const days = Math.floor(serial);
  const seconds = Math.round((serial - days) * 86400);
  return new Date(excelEpoch.getTime() + days * 86400 * 1000 + seconds * 1000);
};

const reportSchemas = {
  report1and2: [
    'operator_id', 'location_name', 'user_id', 'user_type', 'access_point',
    'access_point_label', 'access_point_name', 'key_type', 'user_name',
    'user_phone', 'logged_time', 'duration'
  ],
  report3: [
    'id', 'created_at', 'first_name', 'last_name', 'email', 'mobile',
    'status', 'roles', 'pt_count', 'missing_sales_reports',
    'missing_activity_reports', 'pt25_hour_count', 'pt50_hour_count',
    'pt_promotion_hour_count', 'total_hours', 'total_revenue', 'currency'
  ],
  ptinfo: [
    'id', 'first_name', 'last_name', 'area', 'location'
  ]
};

const validateExcelStructure = (headers, expectedSchema) => {
  if (!headers || !expectedSchema) {
    return {
      isValid: false,
      missingColumns: [],
      extraColumns: [],
      error: 'Invalid headers or schema'
    };
  }

  const normalizedHeaders = headers.map(h => h.trim());
  const normalizedSchema = expectedSchema.map(h => h.trim());

  const missingColumns = normalizedSchema.filter(col => !normalizedHeaders.includes(col));
  const extraColumns = normalizedHeaders.filter(col => !normalizedSchema.includes(col));
  return { 
    isValid: missingColumns.length === 0 && extraColumns.length === 0,
    missingColumns,
    extraColumns
  };
};

const readFile = (file, expectedSchema, isReport1or2) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      try {
        console.log(`Processing file: ${file.name}`);
        console.log(`Expected schema: ${JSON.stringify(expectedSchema)}`);
        
        const bstr = event.target.result;
        const workbook = XLSX.read(bstr, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        
        // Get headers (first row)
        const headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];
        
        console.log(`Actual headers: ${JSON.stringify(headers)}`);

        if (!headers || headers.length === 0) {
          throw new Error(`Empty file or missing headers in ${file.name}`);
        }

        const validationResult = validateExcelStructure(headers, expectedSchema);
        console.log(`Validation result for ${file.name}:`, JSON.stringify(validationResult, null, 2));

        if (!validationResult.isValid) {
          throw new Error(`Invalid file structure in ${file.name}: ${JSON.stringify(validationResult)}`);
        }

        // If validation passes, read all data
        const rawData = XLSX.utils.sheet_to_json(worksheet, { header: headers });

        const processedData = isReport1or2 ? rawData.map(row => ({
          ...row,
          logged_time: excelDateToJSDate(row.logged_time),
          duration: parseInt(row.duration) || 0,
          location_name: row.location_name,
          access_point: row.access_point,
          user_type: row.user_type,
          user_name: row.user_name
        })) : rawData;

        resolve({ name: file.name, data: processedData });
      } catch (error) {
        console.error(`Error processing file ${file.name}:`, error);
        reject(error);
      }
    };

    reader.onerror = (error) => {
      console.error(`Error reading file ${file.name}:`, error);
      reject(new Error(`Error reading file ${file.name}: ${error.message}`));
    };
    reader.readAsBinaryString(file);
  });
};

const handleMultiFileUpload = (e, setData, processingFunction, reportType, setError) => {
  const files = e.target.files;
  const filePromises = [];
  const isReport1or2 = reportType === 'report1and2';

  let helperData = null;
  let dataFiles = [];

  const setHelperData = (data) => {
    //console.log('Setting helper data:', data);
    helperData = data;
  };

  const processFiles = () => {
    //console.log('Processing files. Helper data:', helperData);
    //console.log('Data files:', dataFiles);

    if (reportType === 'report3') {
      //console.log('Entering processDataSales branch');
      if (!helperData || helperData.length === 0) {
        console.error('Helper data is missing or empty');
        throw new Error('Helper file (PTinfo.xlsx) is missing or empty. Please upload it first.');
      }
      
      const flattenedData = dataFiles.map(file => ({
        name: file.name,
        data: file.data
      }));
      //console.log('Flattened data:', flattenedData);
      
      //console.log('processingFunction:', processingFunction.name);
      //console.log('helperData length:', helperData.length);
      //console.log('flattenedData length:', flattenedData.length);
      
      try {
        //console.log('Calling processingFunction');
        const processedData = processingFunction(flattenedData, helperData);
        //console.log('Data processed successfully:', processedData);
        setData(processedData);
      } catch (error) {
        console.error('Error in processingFunction:', error);
        setError(`Error processing data: ${error.message}`);
      }
    } else {
      //console.log('Entering non-processDataSales branch');
      const mergedData = isReport1or2 ? dataFiles.flatMap(file => file.data) : dataFiles.flatMap(file => file.data);
      const processedData = processingFunction(mergedData);
      setData(processedData);
    }
  };

  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    //console.log('Starting to read file:', file.name);

    let schema;
    let isPTInfo = false;

    if (file.name.toLowerCase() === 'ptinfo.xlsx' && reportType === 'report3') {
      schema = reportSchemas.ptinfo;
      isPTInfo = true;
      //console.log('PT info file detected:', file.name, 'using ptinfo schema');
    } else {
      schema = reportSchemas[reportType];
      //console.log('Data file detected:', file.name, 'using schema:', reportType);
    }

    filePromises.push(
      readFile(file, schema, isReport1or2).then(result => {
        if (isPTInfo) {
          //console.log('Setting helper data for file:', file.name);
          setHelperData(result.data);
        } else {
          //console.log('Adding data file:', file.name);
          dataFiles.push(result);
        }
      })
    );
  }

  Promise.all(filePromises)
    .then(() => {
      //console.log('All files processed. Helper data:', helperData);
      //console.log('Data files:', dataFiles);
      if (reportType === 'report3' && (!helperData || helperData.length === 0)) {
        throw new Error('Helper file (PTinfo.xlsx) is missing or empty. Please upload it along with the sales data.');
      }
      processFiles();
    })
    .catch(error => {
      console.error('File processing failed:', error);
      setError(error.message || 'An unexpected error occurred while processing the file');
    });

  e.target.value = '';
};

const FileUpload = ({ setData, processingFunction, reportType }) => {
  const [error, setError] = useState(null);
  const handleFileChange = (e) => {
    setError(null);
    handleMultiFileUpload(e, setData, processingFunction, reportType, setError);
  };

  return (
    <div className="relative inline-block">
      <button
        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
        onClick={() => document.getElementById('fileInput').click()}
      >
        Upload Files
      </button>
      <input
        id="fileInput"
        type="file"
        accept=".xlsx, .xls"
        onChange={handleFileChange}
        className="hidden"
        multiple
      />
      {error && (
      <div className="mt-2 text-red-500">
        {error}
      </div>
    )}
    </div>
  );
};

export default FileUpload;