import React from 'react';
import PropTypes from 'prop-types';
import SummaryTable from './Report3SummaryTable.js';
import IncomePerPT from './IncomePerPT.js';
import IncomePerLocation from './IncomePerLocation.js';
import IncomePerArea from './IncomePerArea.js';
import IncomeDistribution from './IncomeDistribution.js';

const ReportSummary = ({
  summaryData,
  selectedAreas,
  selectedTime,
  years,
  locationSummary,
  areaSummary,
  ptSummary,
  incomeBrackets
}) => {
  console.log('ReportSummary - Props:', {
    summaryDataKeys: summaryData ? Object.keys(summaryData) : [],
    selectedAreas,
    selectedTime,
    years,
    locationSummaryKeys: locationSummary ? Object.keys(locationSummary) : [],
    areaSummaryKeys: areaSummary ? Object.keys(areaSummary) : [],
    ptSummaryKeys: ptSummary ? Object.keys(ptSummary) : [],
    incomeBracketsLength: incomeBrackets ? incomeBrackets.length : 0
  });
  console.log(ptSummary)

  const renderSection = (component, condition) => (
    <div className="mb-8">
      
      {condition ? component : ""}
    </div>
  );

  return (
    <div className="mt-16">
      {renderSection(
        <SummaryTable summaryData={summaryData} selectedAreas={selectedAreas} />,
        summaryData && Object.keys(summaryData).length > 0
      )}

      {renderSection(
        
        <IncomePerPT
          ptSummary={ptSummary}
          selectedTime={selectedTime}
          years={years}
          selectedAreas={selectedAreas}
        />,
        ptSummary && Object.keys(ptSummary).length > 0
      )}

      {renderSection(
        
        <IncomePerLocation
          locationSummary={locationSummary}
          selectedTime={selectedTime}
          years={years}
        />,
        locationSummary && Object.keys(locationSummary).length > 0
      )}

      {renderSection(
        
        <IncomePerArea
          areaSummary={areaSummary}
          selectedTime={selectedTime}
          years={years}
          selectedAreas={selectedAreas}
        />,
        areaSummary && Object.keys(areaSummary).length > 0 && selectedAreas.length > 1
      )}

      {renderSection(
        
        <IncomeDistribution 
          incomeBrackets={incomeBrackets}
          selectedTime={selectedTime}
          years={years}
        />,
        incomeBrackets && incomeBrackets.length > 0
      )}
    </div>
  );
};

ReportSummary.propTypes = {
  summaryData: PropTypes.object,
  selectedAreas: PropTypes.arrayOf(PropTypes.string),
  selectedTime: PropTypes.string,
  years: PropTypes.arrayOf(PropTypes.string),
  locationSummary: PropTypes.object,
  areaSummary: PropTypes.object,
  ptSummary: PropTypes.object,
  incomeBrackets: PropTypes.arrayOf(PropTypes.shape({
    bracket: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired
  }))
};

ReportSummary.defaultProps = {
  summaryData: {},
  selectedAreas: [],
  selectedTime: 'all',
  years: [],
  locationSummary: {},
  areaSummary: {},
  ptSummary: {},
  incomeBrackets: []
};

export default React.memo(ReportSummary);