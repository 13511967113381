import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import BarChart from './Report3BarChart.js';

const IncomePerPT = ({ ptSummary, selectedTime, years, selectedAreas }) => {
    const shouldRender = selectedAreas.length === 1 && (selectedTime.length === 4 || selectedTime.includes('-'));

    const chartData = useMemo(() => {
        if (!shouldRender) return [];

        const processedData = Object.values(ptSummary).map(pt => {
            let total_revenue;
            if (selectedTime.length === 4) {
                // Specific year
                total_revenue = pt.years[selectedTime].total;
            } else {
                // Specific month
                const [year, month] = selectedTime.split('-');
                total_revenue = pt.years[year].months[month];
            }
            return { full_name: pt.full_name, total_revenue };
        });

        return processedData.filter(item => item.total_revenue > 0).sort((a, b) => b.total_revenue - a.total_revenue);
    }, [ptSummary, selectedTime, shouldRender]);

    if (!shouldRender || chartData.length === 0) {
        return null;
    }

    return (
        <div className="mb-8">
            <h2 className="text-xl font-bold mb-4">Income per PT</h2>
            <BarChart
                data={chartData}
                dataKey="total_revenue"
                xAxisDataKey="full_name"
                years={years}
                selectedTime={selectedTime}
            />
        </div>
    );
};

IncomePerPT.propTypes = {
    ptSummary: PropTypes.object.isRequired,
    selectedTime: PropTypes.string.isRequired,
    years: PropTypes.arrayOf(PropTypes.string).isRequired,
    selectedAreas: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default IncomePerPT;