import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import FileUpload from '../components/FileUpload.js';
import FilterControls from '../components/FilterControls.js';
import ReportSummary from '../components/Report3Summary.js';
import YearlyIncomeChart from '../components/Report3YearlyIncomeChart.js';
import { processDataSales } from '../utils/dataProcessing.js';
import useReportData from '../hooks/useReportData.js';
import useFilteredData from '../hooks/useFilteredData.js';


const Report3 = ({ data, setData }) => {
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedTime, setSelectedTime] = useState('all');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (data && data.locations) {
      console.log('Report3 - Data loaded, pre-selecting all locations:', data.locations);
      setSelectedLocations(data.locations.filter(location => location !== 'location'));
      setIsLoading(false);
    } else {
      setError('Failed to load data');
    }
  }, [data]);

  const {
    years,
    locationSummary,
    areaSummary,
    ptSummary,
    summaryData,
    incomeBrackets,
    yearlyIncomeData
  } = useReportData(data, selectedAreas, selectedLocations, selectedTime);

  const { filteredLocations, timeOptions } = useFilteredData(data, selectedAreas, selectedLocations, selectedTime);

  const handleAreaChange = useCallback((e) => {
    const newSelectedAreas = Array.from(e.target.selectedOptions, (option) => option.value);
    console.log('Report3 - Selected areas changed:', newSelectedAreas);
    setSelectedAreas(newSelectedAreas);
    setSelectedLocations([]);
  }, []);

  const handleLocationChange = useCallback((e) => {
    const newSelectedLocations = Array.from(e.target.selectedOptions, (option) => option.value);
    console.log('Report3 - Selected locations changed:', newSelectedLocations);
    setSelectedLocations(newSelectedLocations);
  }, []);

  const handleTimeChange = useCallback((e) => {
    console.log('Report3 - Selected time changed:', e.target.value);
    setSelectedTime(e.target.value);
  }, []);

  console.log('Report3 - Current state:', { 
    dataAvailable: !!data, 
    salesDataAvailable: !!(data && data.salesData), 
    selectedAreas, 
    selectedLocations, 
    selectedTime,
    isLoading,
    yearsAvailable: !!years,
    locationSummaryAvailable: !!locationSummary,
    areaSummaryAvailable: !!areaSummary,
    ptSummaryAvailable: !!ptSummary,
    summaryDataAvailable: !!summaryData,
    incomeBracketsAvailable: !!incomeBrackets,
    yearlyIncomeDataAvailable: !!yearlyIncomeData
  });

  if (isLoading) {
    return <div className="text-center mt-8">Loading data...</div>;
  }

  if (error) {
    return <div className="text-center mt-8 text-red-600">Error: {error}</div>;
  }

  if (!data || Object.keys(data).length === 0) {
    return (
      <div className="flex flex-col items-center mt-8">
        <FileUpload setData={setData} processingFunction={processDataSales} reportType="report3" />
        <p className="mt-4">This report analyzes income per PT, per location, and per Area.</p>
        
      </div>
    );
  }

  const hasFilteredData = selectedLocations.length > 0 && summaryData && Object.keys(summaryData).length > 0;

  return (
    <div>
      <div className="flex justify-center mb-8">
        <FileUpload setData={setData} processingFunction={processDataSales} reportType="report3" />
      </div>
      
      {data && data.salesData && Object.keys(data.salesData).length > 0 ? (
        <div>
          <FilterControls
            areas={(data.areas || []).filter(area => area !== 'area')}
            selectedAreas={selectedAreas}
            handleAreaChange={handleAreaChange}
            filteredLocations={filteredLocations}
            selectedLocations={selectedLocations}
            handleLocationChange={handleLocationChange}
            timeOptions={timeOptions}
            selectedTime={selectedTime}
            handleTimeChange={handleTimeChange}
          />

          {hasFilteredData ? (
            <>
              {(selectedTime === 'all' || selectedTime.length === 4) && yearlyIncomeData && Object.keys(yearlyIncomeData).length > 0 && (
                <div>
                  <div className='mb-24'> 
                    <YearlyIncomeChart
                    data={yearlyIncomeData}
                    selectedTime={selectedTime}
                    years={years}
                    />
                  </div>
                  
                </div>
              )}
              <ReportSummary
                summaryData={summaryData}
                selectedAreas={selectedAreas}
                selectedTime={selectedTime}
                years={years}
                locationSummary={locationSummary}
                areaSummary={areaSummary}
                ptSummary={ptSummary}
                incomeBrackets={incomeBrackets}
              />
            </>
          ) : (
            <p className="text-center mt-8">No data available for the selected filters. Please adjust your selection.</p>
          )}
        </div>
      ) : (
        <><p className='text-center mt-8'>No sales data available. Please upload data to generate the report.</p>
        <p className='text-center mt-4'>Upload an export from Credlock's PT page, and make sure to include the year and name int the file name in this format: year-month, example: 2024-january.</p>
        <p className='text-center mt-4'>If you have multiple files, make sure to upload all files at once.</p>
        <p className='text-center mt-4'>For this report to function, you also have to upload this file: <a href="https://docs.google.com/spreadsheets/d/1FBQkbxVjKgW2KgaYDpYK_FskksWp4CA9/edit?usp=sharing&ouid=104806688883443408750&rtpof=true&sd=true" target="_blank" rel="noreferrer">PTinfo.xlsx</a></p>
      </>
    )}
    </div>
  );
};

Report3.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func.isRequired
};

export default Report3;