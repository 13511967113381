import React from 'react';
import { Bar } from 'react-chartjs-2';
import { getUnifiedColor } from '../utils/helpers.js';

const SavedDataSection = ({
    savedDataTitles,
    setSavedDataTitles,
    currentDataTitle,
    setCurrentDataTitle,
    consolidatedData,
    setConsolidatedData,
    handleSaveClick,
    handleLoadData,
    handleDeleteData,
    options
}) => {
    return (
        <div className='mt-8 bg-gray-100 p-6 rounded-lg border border-gray-200'>
            <div>
                <h3 className="text-xl font-bold mb-8">Saved Data</h3>
                <div className='mb-4'>
                    <input
                        type="text"
                        placeholder="Enter title for dataset"
                        onChange={(e) => setCurrentDataTitle(e.target.value)}
                        className="border p-2"
                    />
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-2"
                        onClick={() => handleSaveClick(currentDataTitle)}>
                        Save New
                    </button>
                    <button
                        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded ml-2"
                        onClick={() => handleSaveClick(currentDataTitle)}>
                        Add to Data
                    </button>
                    <button
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded ml-2"
                        onClick={() => handleDeleteData(currentDataTitle)}>
                        Delete
                    </button>
                </div>
            </div>
            <div className='mt-4'>
                <ul>
                    {savedDataTitles.map((title, idx) => (
                        <button
                            key={idx}
                            className={`font-bold py-2 px-4 rounded mr-2 mt-2 ${currentDataTitle === title ? 'bg-gray-700 text-white' : 'bg-gray-500 hover:bg-gray-700 text-white'}`}
                            onClick={() => handleLoadData(title)}>
                            {title}
                        </button>
                    ))}
                </ul>
            </div>
            {consolidatedData.length > 0 && (
                <div className="chart-container mt-8 mb-8" style={{ height: '400px' }}>
                    <Bar data={{
                        labels: consolidatedData.map(dataItem => dataItem.label),
                        datasets: consolidatedData[0].weeks.map((week, index) => ({
                            label: week,
                            data: consolidatedData.map(dataItem => dataItem.data[index]),
                            backgroundColor: getUnifiedColor(week)
                        }))
                    }} options={options} />
                </div>
            )}
        </div>
    );
};

export default SavedDataSection;