import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ChartBarIcon, ClipboardDocumentListIcon, Cog6ToothIcon, ArrowLeftEndOnRectangleIcon, MoonIcon, SunIcon } from '@heroicons/react/24/outline';
import { useAuth0 } from '@auth0/auth0-react';

interface SidebarProps {
    isOpen: boolean;
    isDarkMode: boolean;
    toggleDarkMode: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, isDarkMode, toggleDarkMode }) => {
  const { logout } = useAuth0();
  const location = useLocation();
  
  const menuItems = [
    {
      label: 'Reports',
      icon: ChartBarIcon,
      subItems: [
        { path: '/', label: 'PT Access' },
        { path: '/report2', label: 'Cleaning Access' },
        { path: '/report3', label: 'PT Sales' },
      ],
    },
    {
      label: 'Audit',
      icon: ClipboardDocumentListIcon,
      subItems: [
        { path: '/audit/history', label: 'History' },
        { path: '/audit/execute', label: 'Execution' },
        { path: '/audit/template', label: 'Templates' },
      ],
    },
    {
      label: 'Settings',
      icon: Cog6ToothIcon,
      path: '/settings',
    },
  ];

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
        clientId: process.env.REACT_APP_AUTH0_CLIENT_ID
      }
    });
  };


  return (
    <div className={`bg-gray-900 text-white h-screen w-64 pt-20 fixed left-0 top-0 overflow-y-auto transition-transform duration-300 ease-in-out transform z-30 ${isOpen ? 'translate-x-0' : '-translate-x-full'} lg:translate-x-0`}>
        <div className="p-5">
        <nav>
          {menuItems.map((item, index) => (
            <div key={index} className="mb-4">
              <div className="flex items-center mb-2">
                <item.icon className="h-5 w-5 mr-2" />
                <span className="font-medium">{item.label}</span>
              </div>
              {item.subItems ? (
                <div className="ml-6 space-y-2">
                  {item.subItems.map((subItem, subIndex) => (
                    <Link
                      key={subIndex}
                      to={subItem.path}
                      className={`block hover:text-blue-400 ${
                        location.pathname === subItem.path ? 'text-blue-400' : ''
                      }`}
                    >
                      {subItem.label}
                    </Link>
                  ))}
                </div>
              ) : (
                <Link
                  to={item.path}
                  className={`ml-6 block hover:text-blue-400 ${
                    location.pathname === item.path ? 'text-blue-400' : ''
                  }`}
                >
                  {item.label}
                </Link>
              )}
            </div>
          ))}
        </nav>
      </div>
      <div className="absolute bottom-0 left-0 right-0 p-5">
      <button
          onClick={toggleDarkMode}
          className={`flex items-center justify-center w-full mb-4 py-2 ${isDarkMode ? 'bg-gray-700 hover:bg-gray-600' : 'bg-gray-800 hover:bg-gray-700'} rounded`}
        >
          {isDarkMode ? (
            <SunIcon className="h-5 w-5 mr-2" />
          ) : (
            <MoonIcon className="h-5 w-5 mr-2" />
          )}
          {isDarkMode ? 'Light Mode' : 'Dark Mode'}
        </button>
        <button
          onClick={handleLogout}
          className="flex items-center justify-center w-full py-2 bg-red-600 hover:bg-red-700 rounded"
        >
          <ArrowLeftEndOnRectangleIcon className="h-5 w-5 mr-2" />
          Logout
        </button>
      </div>
    </div>
  );
};

export default Sidebar;