import { useMemo } from 'react';

const useFilteredData = (data, selectedAreas, selectedLocations, selectedTime) => {
  const filteredLocations = useMemo(() => {
    if (selectedAreas.length === 0) return [];
    return (data.locations || []).filter(location => 
      data.salesData.some(item => 
        selectedAreas.includes(item.area) && item.location === location
      )
    );
  }, [data, selectedAreas]);

  const timeOptions = useMemo(() => {
    if (!data || !data.salesData) return [];
    
    const years = [...new Set(data.salesData.map(item => item.year))].sort((a, b) => a - b);
    const options = [{ value: 'all', label: 'All Years' }];
    
    years.forEach(year => {
      options.push({ value: year, label: year.toString() });
      const monthsInYear = data.salesData
        .filter(item => item.year === year)
        .map(item => item.month)
        .filter((v, i, a) => a.indexOf(v) === i)
        .sort((a, b) => a - b);
      monthsInYear.forEach(month => {
        options.push({ value: `${year}-${month}`, label: `${year} - ${month.split(' ')[0]}` });
      });
    });
    
    return options;
  }, [data]);

  return { filteredLocations, timeOptions };
};

export default useFilteredData;