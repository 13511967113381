import React from 'react';
import { useRoutes, Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import LoginButton from './components/LoginButton';
import EmployeeAccessDashboard from './pages/EmployeeAccessDashboard.js';
import AuditDashboard from './pages/AuditDashboard.tsx';
import Settings from './pages/Settings';
import Layout from './components/Layout';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

const App = () => {
  const { isAuthenticated, isLoading, error, user } = useAuth0();

  const routes = useRoutes([
    {
      path: '/',
      element: (
        <ProtectedRoute>
          <Layout />
        </ProtectedRoute>
      ),
      children: [
        { index: true, element: <EmployeeAccessDashboard report="1" /> },
        { path: 'report2', element: <EmployeeAccessDashboard report="2" /> },
        { path: 'report3', element: <EmployeeAccessDashboard report="3" /> },
        { path: 'audit/*', element: <AuditDashboard /> },
        { path: 'settings', element: <Settings /> },
      ],
    },
    {
      path: '/login',
      element: <LoginButton />,
    },
  ]);

  if (isLoading) {
    return <div className="text-center mt-16">Loading...</div>;
  }

  if (error) {
    return <div>Authentication Error: {error.message}</div>;
  }

  if (!isAuthenticated) {
    return <LoginButton />;
  }

  if (user && !user.email_verified) {
    return (
      <div>
        <p>Please verify your email to access the dashboard.</p>
      </div>
    );
  }

  return routes;
};

export default App;