import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import BarChart from './Report3BarChart.js';

const IncomePerLocation = ({ locationSummary, selectedTime, years }) => {
    //console.log('!!!IncomePerLocation - Input:', { locationSummary, selectedTime, years });

    const shouldRender = selectedTime === 'all' || selectedTime.length === 4;

    const chartData = useMemo(() => {
        if (!shouldRender) return [];

        const data = Object.values(locationSummary).map(location => {
            if (selectedTime === 'all') {
                const yearlyData = years.reduce((acc, year) => {
                    acc[year] = location.years[year].total;
                    return acc;
                }, {});
                return {
                    location: location.location,
                    ...yearlyData,
                    total: location.total
                };
            } else if (selectedTime.length === 4) {
                return {
                    location: location.location,
                    total_revenue: location.years[selectedTime].total
                };
            } else {
                // Return a default object for any other case
                return {
                    location: location.location,
                    total_revenue: 0
                };
            }
        }).filter(location => {
            if (selectedTime === 'all') {
                return years.some(year => location[year] > 0);
            } else {
                return location.total_revenue > 0;
            }
        });

        //console.log('!!!IncomePerLocation - Processed chart data:', data);
        return data.sort((a, b) => b.total_revenue - a.total_revenue);
    }, [locationSummary, selectedTime, shouldRender, years]);

    if (!shouldRender || chartData.length === 0) {
        //console.log('IncomePerLocation - Not rendering:', { shouldRender, chartDataLength: chartData.length });
        return null;
    }

    //console.log('!!!IncomePerLocation - Rendering chart with data:', chartData);

    return (
        <div className="mb-8">
            <h2 className="text-xl font-bold mb-4">Income per Location</h2>
            <BarChart
                data={chartData}
                dataKey={selectedTime === 'all' ? null : "total_revenue"}
                xAxisDataKey="location"
                years={years}
                selectedTime={selectedTime}
            />
        </div>
    );
};

IncomePerLocation.propTypes = {
    locationSummary: PropTypes.object.isRequired,
    selectedTime: PropTypes.string.isRequired,
    years: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default IncomePerLocation;