import { useMemo } from 'react';
import { calculateSummaryData, calculateIncomeBrackets } from '../utils/helpers.js';

const useReportData = (data, selectedAreas, selectedLocations, selectedTime) => {
   //console.log('useReportData - Input:', { 
   //     dataExists: !!data,
   //     salesDataExists: !!(data && data.salesData),
   //     salesDataLength: data && data.salesData ? data.salesData.length : 0,
   //     selectedAreas, 
   //     selectedLocations, 
   //     selectedTime 
   // });

    const years = useMemo(() => {
        if (!data || !data.salesData) {
            //console.log('useReportData - No sales data available for years calculation');
            return [];
        }
        try {
            const calculatedYears = [...new Set(data.salesData.map(item => item.year))].sort();
            //console.log('useReportData - Calculated years:', calculatedYears);
            return calculatedYears;
        } catch (error) {
            //console.error('useReportData - Error calculating years:', error);
            return [];
        }
    }, [data]);

    const standardizeMonth = (monthString) => {
        return monthString ? monthString.split(' ')[0] : '';
    };

    const filteredData = useMemo(() => {
        if (!data || !data.salesData) {
            //console.log('useReportData - No sales data available for filtering');
            return [];
        }
    
        try {
            const filtered = data.salesData.filter(item => {
                const areaCondition = selectedAreas.length === 0 || selectedAreas.includes(item.area);
                const locationCondition = selectedLocations.length === 0 || selectedLocations.includes(item.location);
                let timeCondition;
                if (selectedTime === 'all') {
                    timeCondition = true;
                } else if (selectedTime.length === 4) {
                    timeCondition = item.year === selectedTime;
                } else {
                    const [selectedYear, selectedMonth] = selectedTime.split('-');
                    timeCondition = item.year === selectedYear && standardizeMonth(item.month) === selectedMonth;
                }
                
                return areaCondition && locationCondition && timeCondition;
            });

            //console.log('useReportData - Filtered data:', { 
            //    originalLength: data.salesData.length,
            //    filteredLength: filtered.length,
            //    sampleItem: filtered.length > 0 ? filtered[0] : null
            //});

            return filtered;
        } catch (error) {
            console.error('useReportData - Error filtering data:', error);
            return [];
        }
    }, [data, selectedAreas, selectedLocations, selectedTime]);

    const locationSummary = useMemo(() => {
        try {
            const summary = processSummaryData(filteredData, 'location', years, standardizeMonth);
            //console.log('useReportData - Location summary:', { keys: Object.keys(summary), sampleValue: Object.values(summary)[0] });
            return summary;
        } catch (error) {
            console.error('useReportData - Error calculating location summary:', error);
            return {};
        }
    }, [filteredData, years]);

    const areaSummary = useMemo(() => {
        try {
            const summary = processSummaryData(filteredData, 'area', years, standardizeMonth);
            //console.log('useReportData - Area summary:', { keys: Object.keys(summary), sampleValue: Object.values(summary)[0] });
            return summary;
        } catch (error) {
            console.error('useReportData - Error calculating area summary:', error);
            return {};
        }
    }, [filteredData, years]);

    const ptSummary = useMemo(() => {
        try {
            const summary = processSummaryData(filteredData, 'full_name', years, standardizeMonth);
            //console.log('useReportData - PT summary:', { keys: Object.keys(summary), sampleValue: Object.values(summary)[0] });
            return summary;
        } catch (error) {
            console.error('useReportData - Error calculating PT summary:', error);
            return {};
        }
    }, [filteredData, years]);

    const summaryData = useMemo(() => {
        try {
            const summary = calculateSummaryData(filteredData, selectedTime, years);
            //console.log('useReportData - Summary data:', summary);
            return summary || {};
        } catch (error) {
            console.error('useReportData - Error calculating summary data:', error);
            return {};
        }
    }, [filteredData, selectedTime, years]);

    const incomeBrackets = useMemo(() => {
        //console.log('useReportData - Calculating income brackets:', { selectedTime, filteredDataLength: filteredData.length });
        try {
            const calculateTotalRevenue = (item) => item.total_revenue || 0;
            const brackets = calculateIncomeBrackets(filteredData, selectedTime, years, calculateTotalRevenue);
            //console.log('useReportData - Income brackets result:', brackets);
            return brackets || [];
        } catch (error) {
            console.error('useReportData - Error calculating income brackets:', error);
            return [];
        }
    }, [filteredData, selectedTime, years]);

    const yearlyIncomeData = useMemo(() => {
        if (!filteredData.length) {
            //console.log('useReportData - No filtered data for yearly income calculation');
            return {};
        }

        try {
            const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

            let result;
            if (selectedTime === 'all') {
                result = years.reduce((acc, year) => {
                    acc[year] = {
                        total: filteredData.reduce((sum, item) => item.year === year ? sum + (item.total_revenue || 0) : sum, 0),
                        months: months.reduce((monthAcc, month) => {
                            monthAcc[month] = filteredData.reduce((sum, item) => 
                                item.year === year && standardizeMonth(item.month) === month ? sum + (item.total_revenue || 0) : sum, 0);
                            return monthAcc;
                        }, {})
                    };
                    return acc;
                }, {});
            } else if (selectedTime.length === 4) {
                result = {
                    [selectedTime]: {
                        total: filteredData.reduce((sum, item) => sum + (item.total_revenue || 0), 0),
                        months: months.reduce((acc, month) => {
                            acc[month] = filteredData.reduce((sum, item) => 
                                standardizeMonth(item.month) === month ? sum + (item.total_revenue || 0) : sum, 0);
                            return acc;
                        }, {})
                    }
                };
            } else {
                const [year, month] = selectedTime.split('-');
                result = {
                    [year]: {
                        total: filteredData.reduce((sum, item) => sum + (item.total_revenue || 0), 0),
                        months: {
                            [month]: filteredData.reduce((sum, item) => 
                                standardizeMonth(item.month) === month ? sum + (item.total_revenue || 0) : sum, 0)
                        }
                    }
                };
            }

            //console.log('useReportData - Yearly income data:', result);
            return result;
        } catch (error) {
            console.error('useReportData - Error calculating yearly income data:', error);
            return {};
        }
    }, [filteredData, selectedTime, years]);

    /*console.log('useReportData - Output:', { 
        years, 
        filteredDataLength: filteredData.length,
        locationSummaryKeys: Object.keys(locationSummary), 
        areaSummaryKeys: Object.keys(areaSummary), 
        ptSummaryKeys: Object.keys(ptSummary), 
        summaryDataKeys: Object.keys(summaryData),
        incomeBracketsLength: incomeBrackets.length, 
        yearlyIncomeDataKeys: Object.keys(yearlyIncomeData) 
    });*/

    return {
        years,
        filteredData,
        locationSummary,
        areaSummary,
        ptSummary,
        summaryData,
        incomeBrackets,
        yearlyIncomeData,
        selectedTime
    };
};

const processSummaryData = (data, groupBy, years, standardizeMonth) => {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return data.reduce((acc, item) => {
        const key = item[groupBy];
        if (!acc[key]) {
            acc[key] = { [groupBy]: key, total: 0, years: {} };
            years.forEach(year => {
                acc[key].years[year] = { total: 0, months: {} };
                months.forEach(month => {
                    acc[key].years[year].months[month] = 0;
                });
            });
        }
        acc[key].total += item.total_revenue || 0;
        acc[key].years[item.year].total += item.total_revenue || 0;
        const standardizedMonth = standardizeMonth(item.month);
        acc[key].years[item.year].months[standardizedMonth] += item.total_revenue || 0;
        return acc;
    }, {});
};

export default useReportData;