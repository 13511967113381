import React, { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
import { Bars3Icon } from '@heroicons/react/24/outline';

const Layout: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsSidebarOpen(false);
  }, [location]);

  const getPageTitle = () => {
    const path = location.pathname;
    if (path === '/') return 'PT Access Report';
    if (path === '/report2') return 'Cleaning Access Report';
    if (path === '/report3') return 'PT Sales Report';
    if (path.startsWith('/audit')) return 'Audit';
    if (path === '/settings') return 'Settings';
    return 'Dashboard';
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <div className={`flex h-screen ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-black'}`}>
      <Sidebar isOpen={isSidebarOpen} isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />
      <div className="flex-1 flex flex-col overflow-hidden">
        <header className={`shadow-sm z-50 ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
          <div className="max-w-7xl px-2 md:px-6 py-4 lg:px-8 flex items-center">
            <button
              onClick={toggleSidebar}
              className={`lg:hidden p-2 rounded-md ${isDarkMode ? 'text-gray-300 hover:text-gray-100 hover:bg-gray-700' : 'text-gray-400 hover:text-gray-500 hover:bg-gray-100'} focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500`}
            >
              <Bars3Icon className="h-6 w-6" />
            </button>
            <h1 className={`text-2xl ml-2 mb-0 font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>{getPageTitle()}</h1>
          </div>
        </header>
        <main className={`flex-1 overflow-x-hidden overflow-y-auto lg:pl-64 ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
          <div className="max-w-7xl py-6 sm:px-6 lg:px-8">
            <Outlet context={{ isDarkMode }} />
          </div>
        </main>
      </div>
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-gray-600 bg-opacity-75 z-10 lg:hidden"
          onClick={toggleSidebar}
        ></div>
      )}
    </div>
  );
};

export default Layout;