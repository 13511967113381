import { useState, useEffect, useCallback } from 'react';
import {
    loadFromLocalStorage,
    handleSaveClick,
    handleLoadData,
    handleDeleteData,
    calculateAverages
} from '../utils/helpers.js';

const useSavedData = (data, selectedLocations, selectedEmployees) => {
    const [consolidatedData, setConsolidatedData] = useState([]);
    const [savedDataTitles, setSavedDataTitles] = useState([]);
    const [currentDataTitle, setCurrentDataTitle] = useState('');

    useEffect(() => {
        const savedData = loadFromLocalStorage('saved_data_titles') || [];
        setSavedDataTitles(savedData);
        const loadedData = loadFromLocalStorage('consolidated_data') || [];
        setConsolidatedData(loadedData);
    }, []);

    const handleSaveClickWrapper = useCallback((title) => {
        const averages = calculateAverages(data, selectedLocations, selectedEmployees);
        const newConsolidatedData = handleSaveClick(title, selectedLocations, averages, savedDataTitles, setSavedDataTitles);
        setConsolidatedData(newConsolidatedData);
    }, [data, selectedLocations, selectedEmployees, savedDataTitles]);

    const handleLoadDataWrapper = useCallback((title) => {
        const loadedData = handleLoadData(title);
        setConsolidatedData(loadedData);
        setCurrentDataTitle(title);
    }, []);

    const handleDeleteDataWrapper = useCallback((title) => {
        handleDeleteData(title, savedDataTitles, setSavedDataTitles, currentDataTitle, setCurrentDataTitle, setConsolidatedData);
    }, [savedDataTitles, currentDataTitle]);

    return {
        consolidatedData,
        savedDataTitles,
        currentDataTitle,
        setCurrentDataTitle,
        handleSaveClickWrapper,
        handleLoadDataWrapper,
        handleDeleteDataWrapper
    };
};

export default useSavedData;