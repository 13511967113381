import { getWeekNumberISO8601 } from './helpers.js';

export const processData = (rawData) => {
  const weeklyData = {};
  const monthlyData = {};
  const yearlyData = {};
  const employeeStats = {};
  const timeSlotData = {};
  const locationStats = {};
  const yearKey1 = new Date(rawData[0].logged_time).getFullYear();
 
  rawData.forEach(({ user_name, user_type, logged_time, duration, access_point, location_name }) => {
    if ((user_type !== "pt" && user_type !=="admin") || (access_point !== "arrival" && access_point !== "departure")) {
      return; // Skip this iteration and move to the next row
    }
    const date = new Date(logged_time);
    const weekStart = new Date(date.getFullYear(), date.getMonth(), date.getDate() - (date.getDay() || 7) + 1);
    const weekEnd = new Date(weekStart);
    weekEnd.setDate(weekEnd.getDate() + 6);
    const weekNumber = getWeekNumberISO8601(weekStart); 
    const weekKey = `Week ${weekNumber}`;
    const monthStart = new Date(date.getFullYear(), date.getMonth(), 1);
    const monthKey = monthStart.toLocaleString('en-GB', { month: 'long' });
    const yearKey = date.getFullYear();
    
    // Week data
    if (!weeklyData[weekKey]) {
      weeklyData[weekKey] = {};
    }
    if (!weeklyData[weekKey][user_name]) {
      weeklyData[weekKey][user_name] = { days: new Set(), totalDuration: 0, satDays: new Set(), sunDays: new Set(), satDuration: 0, sunDuration: 0, location: '' };
    }
    weeklyData[weekKey][user_name].days.add(date.toDateString()); 
    weeklyData[weekKey][user_name].totalDuration += duration;
    weeklyData[weekKey][user_name].location = location_name;

    if (date.getDay() === 6) {
          weeklyData[weekKey][user_name].satDays.add(date.toDateString());
          weeklyData[weekKey][user_name].satDuration += duration;
        } else if (date.getDay() === 0) {
          weeklyData[weekKey][user_name].sunDays.add(date.toDateString());
          weeklyData[weekKey][user_name].sunDuration += duration;
        }

    // Month data
    if (!monthlyData[monthKey]) {
      monthlyData[monthKey] = {};
    }
    if (!monthlyData[monthKey][user_name]) {
      monthlyData[monthKey][user_name] = { days: new Set(), totalDuration: 0, satDays: new Set(), sunDays: new Set(), satDuration: 0, sunDuration: 0, location: '' };
    }
    monthlyData[monthKey][user_name].days.add(date.toDateString());
    monthlyData[monthKey][user_name].totalDuration += duration;
    monthlyData[monthKey][user_name].location = location_name;

    //yearly data
    if (!yearlyData[yearKey]) {
      yearlyData[yearKey] = {};
    }
    if (!yearlyData[yearKey][user_name]) {
      yearlyData[yearKey][user_name] = { days: new Set(), totalDuration: 0, location: '' };
    }
    yearlyData[yearKey][user_name].days.add(date.toDateString());
    yearlyData[yearKey][user_name].totalDuration += duration;
    yearlyData[yearKey][user_name].location = location_name;

    // Location stats
    if (!locationStats[location_name]) {
      locationStats[location_name] = {};
    }
    if (!locationStats[location_name][user_name]) {
      locationStats[location_name][user_name] = { days: new Set(), totalDuration: 0, satDays: new Set(), sunDays: new Set(), satDuration: 0, sunDuration: 0, employees: new Set() };
    }
    locationStats[location_name][user_name].days.add(date.toDateString());
    locationStats[location_name][user_name].totalDuration += duration;
    locationStats[location_name][user_name].employees.add(user_name);

    // Employee stats
    if (!employeeStats[user_name]) {
      employeeStats[user_name] = { totalDays: new Set(), totalDuration: 0, satDays: new Set(), sunDays: new Set(), satDuration: 0, sunDuration: 0, location: '' };
    }
    employeeStats[user_name].totalDays.add(date.toDateString());
    employeeStats[user_name].totalDuration += duration;
    employeeStats[user_name].location = location_name;
    
    // Weekends
    if (date.getDay() === 6) {
      employeeStats[user_name].satDays.add(date.toDateString());
      employeeStats[user_name].satDuration += duration;
    } else if (date.getDay() === 0) {
      employeeStats[user_name].sunDays.add(date.toDateString());
      employeeStats[user_name].sunDuration += duration;
    }

    // Time slot stats
    const hour = date.getHours();
    let timeSlot;
    if (hour >= 5 && hour < 9) {
      timeSlot = '5-9';
  } else if (hour >= 9 && hour < 12) {
      timeSlot = '9-12';
  } else if (hour >= 12 && hour < 15) {
      timeSlot = '12-15';
  } else if (hour >= 15 && hour < 18) {
      timeSlot = '15-18';
  } else if (hour >= 18 && hour < 21) {
      timeSlot = '18-21';
  } else if (hour >= 21 && hour <= 24) {
      timeSlot = '21-24';
  }

    if (!timeSlotData[date.toLocaleString('en-GB', { day: '2-digit', month: '2-digit' })]) {
      timeSlotData[date.toLocaleString('en-GB', { day: '2-digit', month: '2-digit' })] = {};
    }
    if (!timeSlotData[date.toLocaleString('en-GB', { day: '2-digit', month: '2-digit' })][timeSlot]) {
      timeSlotData[date.toLocaleString('en-GB', { day: '2-digit', month: '2-digit' })][timeSlot] = {};
    }
    if (!timeSlotData[date.toLocaleString('en-GB', { day: '2-digit', month: '2-digit' })][timeSlot][user_name]) {
      timeSlotData[date.toLocaleString('en-GB', { day: '2-digit', month: '2-digit' })][timeSlot][user_name] = true;
    }
  });
  
  //Weekly charts
  const chartWeeklyData = Object.entries(weeklyData).map(([week, employees]) => ({
    week,
    ...Object.fromEntries(Object.entries(employees).map(([name, { days, totalDuration }]) => [
      name,
      { days: days.size, duration: Math.round((totalDuration / 60) * 10) / 10 } // Convert minutes to hours
    ]))
  })).sort((a, b) => new Date(a.week.split(' - ')[0]) - new Date(b.week.split(' - ')[0]));

  //Monthly charts
  const chartMonthlyData = Object.entries(monthlyData).map(([month, employees]) => ({
    month,
    ...Object.fromEntries(Object.entries(employees).map(([name, { days, totalDuration }]) => [
      name,
      { days: days.size, duration: Math.round((totalDuration / 60) * 10) / 10 } // Convert minutes to hours
    ]))
  })).sort((a, b) => new Date(a.month, 1) - new Date(b.month, 1));

  //Location charts
  const chartLocationData = Object.fromEntries(
    Object.entries(locationStats).map(([location, employees]) => [
      location,
      Object.fromEntries(
        Object.entries(employees).map(([name, { days, totalDuration }]) => [
          name,
          { days: days.size, duration: Math.round((totalDuration / 60) * 10) / 10 } // Convert minutes to hours
        ])
      )
    ])
  );

  const stats = Object.fromEntries(Object.entries(employeeStats).map(([name, { totalDays, totalDuration, satDays, sunDays, satDuration, sunDuration, location }]) => {
    
      return [
        name,
        {
          totalDays: totalDays.size,
          avgDaysPerWeek: totalDays.size / Object.keys(weeklyData).length,
          totalHours: Math.round(totalDuration / 60),
          avgHoursPerDay: Math.round((totalDuration / 60) / totalDays.size * 10) / 10,
          satDays: satDays.size,
          sunDays: sunDays.size,
          satDuration: (satDays.size === 0) ? 0 : Math.round(satDuration / satDays.size),
          sunDuration: (sunDays.size === 0) ? 0 : Math.round(sunDuration / sunDays.size),
          location,
          // Correctly access yearly data
          yearlyDays: yearlyData[yearKey1] ? yearlyData[yearKey1][name].days.size : 0, // Defaults to 0 if year data doesn't exist
          yearlyDuration: yearlyData[yearKey1] ? Math.round(yearlyData[yearKey1][name].totalDuration / 60) : 0 // Defaults to 0 if year data doesn't exist
        }
      ];
    }));
    
  console.log(timeSlotData)
  console.log(weeklyData)
  console.log(monthlyData)
  console.log(yearlyData)   
  console.log(employeeStats)
  console.log(locationStats)
  console.log(chartLocationData)
  console.log(stats)
    
  return { chartWeeklyData, chartMonthlyData, chartLocationData, stats, timeSlotData, locationStats, yearlyData };
};


export const processDataCleaners = (rawData) => {
  const locationStats = {};
  const locations = {};
  let earliestDate = new Date();
  let latestDate = new Date(0);
  const allUsers = new Set();

  // First pass: Organize data by location, week, day, and user
  rawData.forEach(({ location_name, logged_time, duration, access_point, user_type, user_name }) => {
    if (user_type !== "free-pass" || (access_point !== "arrival" && access_point !== "departure")) {
      return; // Skip this iteration and move to the next row
    }
    const date = new Date(logged_time);
    const weekNumber = getWeekNumberISO8601(date);
    const dayKey = date.toISOString().split('T')[0]; // YYYY-MM-DD format

    if (date < earliestDate) earliestDate = date;
    if (date > latestDate) latestDate = date;
    
    // Existing locationStats logic
    if (!locationStats[location_name]) locationStats[location_name] = {};
    if (!locationStats[location_name][weekNumber]) locationStats[location_name][weekNumber] = {};
    if (!locationStats[location_name][weekNumber][dayKey]) locationStats[location_name][weekNumber][dayKey] = {};
    if (!locationStats[location_name][weekNumber][dayKey][user_name]) {
      locationStats[location_name][weekNumber][dayKey][user_name] = {
        durations: [],
        accessPoints: [],
        totalDuration: 0,
      };
    }

    locationStats[location_name][weekNumber][dayKey][user_name].durations.push(duration);
    locationStats[location_name][weekNumber][dayKey][user_name].accessPoints.push(access_point);
    locationStats[location_name][weekNumber][dayKey][user_name].totalDuration += duration;

    // New structure for missing days calculation
    if (!locations[location_name]) locations[location_name] = {};
    if (!locations[location_name][dayKey]) locations[location_name][dayKey] = new Set();
    locations[location_name][dayKey].add(user_name);
    allUsers.add(user_name);
  });

  // Second pass: Calculate adjusted durations (keep this as it's important for weekly averages)
  Object.values(locationStats).forEach(location => {
    Object.values(location).forEach(week => {
      Object.values(week).forEach(day => {
        Object.values(day).forEach(user => {
          const { accessPoints, totalDuration } = user;
          let adjustedDuration = totalDuration;

          if (
            accessPoints.length >= 4 &&
            accessPoints[0] === 'arrival' &&
            accessPoints[1] === 'arrival' &&
            accessPoints[accessPoints.length - 2] === 'departure' &&
            accessPoints[accessPoints.length - 1] === 'departure'
          ) {
            adjustedDuration = totalDuration * 2;
          }

          user.adjustedDuration = adjustedDuration;
        });
      });
    });
  });

  // Convert Sets to Arrays in the new structure
  Object.keys(locations).forEach(location => {
    Object.keys(locations[location]).forEach(day => {
      locations[location][day] = Array.from(locations[location][day]);
    });
  });

  //console.log(locations); //Everyday of location
  console.log(locationStats); //Everyday per week of location

  return {
    locationStats, // Keep this for existing functionality
    locations, // New structure for missing days calculation
    dateRange: {
      start: earliestDate.toISOString().split('T')[0],
      end: latestDate.toISOString().split('T')[0]
    },
    allUsers: Array.from(allUsers)
  };
};

export const processDataSales = (rawData, helperData) => {
  //console.log('Entering processDataSales function');
  //console.log('Raw data type:', typeof rawData);
  //console.log('Raw data length:', Array.isArray(rawData) ? rawData.length : 'Not an array');
  //console.log('Helper data type:', typeof helperData);
  //console.log('Helper data length:', Array.isArray(helperData) ? helperData.length : 'Not an array');

  if (!rawData || !Array.isArray(rawData) || rawData.length === 0) {
    console.error('Raw data is missing or empty');
    throw new Error("Raw data is missing or empty. Please upload sales data files.");
  }

  if (!helperData || !Array.isArray(helperData) || helperData.length === 0) {
    console.error('Helper data is missing or empty');
    throw new Error("Helper file (PTinfo.xlsx) is missing or empty. Please upload it first.");
  }

  //console.log('Number of sales data files:', rawData.length);
  //console.log('Number of PT info entries:', helperData.length);

  const ptInfo = helperData.reduce((acc, pt) => {
    if (!pt.id) {
      console.warn('PT info entry missing id:', pt);
      return acc;
    }
    acc[pt.id] = { ...pt, full_name: `${pt.first_name} ${pt.last_name}` };
    return acc;
  }, {});

  //console.log('Number of processed PT info entries:', Object.keys(ptInfo).length);

  const salesData = [];
  const areas = new Set();
  const locations = new Set();
  const months = new Set();
  const years = new Set();

  rawData.forEach((file, index) => {
    //console.log(`Processing file ${index + 1}/${rawData.length}: ${file.name}`);
    const fileName = file.name.toLowerCase();
    const yearMonthMatch = fileName.match(/(\d{4})[- ](january|february|march|april|may|june|july|august|september|october|november|december)/);
    
    if (!yearMonthMatch) {
      console.warn(`Skipping file ${fileName} due to invalid name format`);
      return;
    }

    const [, year, month] = yearMonthMatch;
    const monthYear = `${month.charAt(0).toUpperCase() + month.slice(1)} ${year}`;
    months.add(monthYear);
    years.add(year);

    //console.log(`File ${file.name} data entries: ${file.data.length}`);

    file.data.forEach((row, rowIndex) => {
      if (!row.id) {
        console.warn(`Row ${rowIndex} in file ${file.name} is missing id:`, row);
        return;
      }

      if (!ptInfo[row.id]) {
        console.warn(`PT info not found for id ${row.id} in file ${file.name}`);
        return;
      }

      const pt = ptInfo[row.id];
      areas.add(pt.area);
      locations.add(pt.location);

      salesData.push({
        id: row.id,
        full_name: pt.full_name,
        area: pt.area,
        location: pt.location,
        month: monthYear,
        year: year,
        pt25_hour_count: parseInt(row.pt25_hour_count) || 0,
        pt50_hour_count: parseInt(row.pt50_hour_count) || 0,
        pt_promotion_hour_count: parseInt(row.pt_promotion_hour_count) || 0,
        total_hours: parseInt(row.total_hours) || 0,
        total_revenue: Math.round(parseFloat(row.total_revenue) || 0)
      });
    });
  });

  //console.log('Processed sales data entries:', salesData.length);
  //console.log('Unique areas:', areas.size);
  //console.log('Unique locations:', locations.size);
  //console.log('Unique months:', months.size);
  //console.log('Unique years:', years.size);

  const locationSummary = Array.from(locations).map(location => ({
    location,
    total_revenue: salesData.filter(item => item.location === location)
      .reduce((sum, item) => sum + item.total_revenue, 0)
  }));

  const areaSummary = Array.from(areas).map(area => ({
    area,
    total_revenue: salesData.filter(item => item.area === area)
      .reduce((sum, item) => sum + item.total_revenue, 0)
  }));

  const yearSummary = Array.from(years).map(year => ({
    year,
    total_revenue: salesData.filter(item => item.year === year)
      .reduce((sum, item) => sum + item.total_revenue, 0)
  }));

  //console.log('Location summary:', locationSummary);
  //console.log('Area summary:', areaSummary);
  //console.log('Year summary:', yearSummary);

  return {
    salesData,
    areas: Array.from(areas),
    locations: Array.from(locations),
    months: Array.from(months),
    years: Array.from(years),
    locationSummary,
    areaSummary,
    yearSummary
  };
};
