import React, { useState } from 'react';
import FileUpload from '../components/FileUpload.js';
import EmployeeSelect from '../components/EmployeeSelect.js';
import AxisToggleButtons from '../components/AxisToggleButtons.js';
import CustomBarChart from '../components/Report1BarChart.js';
import TimeSlotAttendance from '../components/Report1TimeSlotAttendance.js';
import StatisticsTable from '../components/Report1StatisticsTable.js';
import { processData } from '../utils/dataProcessing.js';

const Report1 = ({ data, axisVisibility, handleToggleAxis, setData }) => {
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    
    const handleEmployeeChange = (e) => {
        const selectedEmployees = Array.from(e.target.selectedOptions, (option) => option.value);
        setSelectedEmployees(selectedEmployees);
    };

    if (!data || (Array.isArray(data.chartWeeklyData) && data.chartWeeklyData.length === 0)) {
        return (
          <div>
            <div className="flex justify-center mb-8 space-x-4">
                <FileUpload setData={setData} processingFunction={processData} reportType="report1and2" />
            </div>
            <div className="flex justify-center mb-8 space-x-4">
                <p>This report analyzes pt and admin access from the Access log in Credlock.</p>
            </div>
          </div>
        );
    }

    const aggregatedLocationData = Object.entries(data.chartLocationData).map(([location, employees]) => {
        const aggregated = Object.entries(employees).reduce((acc, [name, { days, duration }]) => {
        if (selectedEmployees.includes(name)) {
            acc.days += days;
            acc.duration += duration;
        }
        return acc;
        }, { days: 0, duration: 0 });
        return { location, ...aggregated };
    }).filter(locationData => locationData.days > 0 || locationData.duration > 0);
    
    return (
        <div>
            <div className="flex justify-center mb-8 space-x-4">
                <FileUpload setData={setData} processingFunction={processData} reportType="report1and2" />
            </div>
            <div className="flex justify-center mb-8 space-x-4">
            <EmployeeSelect employees={Object.keys(data.stats)} selectedEmployees={selectedEmployees} handleEmployeeChange={handleEmployeeChange} />
            </div>
            
            <AxisToggleButtons axisVisibility={axisVisibility} handleToggleAxis={handleToggleAxis} />

            {/* Weekly Chart */}
            <CustomBarChart 
                data={data.chartWeeklyData} 
                axisVisibility={axisVisibility} 
                xAxisDataKey="week"
                selectedEmployees={selectedEmployees}
                stats={data.stats}
                chartType="weekly"
            />

            {/* Monthly Chart */}
            <CustomBarChart 
                data={data.chartMonthlyData} 
                axisVisibility={axisVisibility} 
                xAxisDataKey="month"
                selectedEmployees={selectedEmployees}
                stats={data.stats}
                chartType="monthly"
            />

            {aggregatedLocationData.length > 1 && (
                        <CustomBarChart 
                                    data={aggregatedLocationData} 
                                    axisVisibility={axisVisibility} 
                                    xAxisDataKey="location"
                                    selectedEmployees={selectedEmployees}
                                    stats={data.stats}
                                    chartType="location"
                        />
            )}

            <TimeSlotAttendance 
                data={data}
                selectedEmployees={selectedEmployees}
                stats={data.stats}
            />

            <StatisticsTable 
                stats={data.stats}
                selectedEmployees={selectedEmployees}
            />
        </div>
    );
};

export default Report1;