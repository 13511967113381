import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import DatePicker from 'react-datepicker'; 
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import config from '../../config';
import { compressImage, uploadImage, compressImages, uploadImages } from '../../utils/fileUpload';
import { XCircleIcon, CameraIcon, PhotoIcon } from '@heroicons/react/24/solid';
//import { CameraIcon } from '@heroicons/react/24/outline';

interface Question {
  id: number;
  text: string;
  section_id: number;
  order_index: number;
}

interface Section {
  id: number;
  name: string;
  description: string;
  order_index: number;
  questions: Question[];
  image_urls: string[];
  score: number;
}

interface AuditResponse {
  question_id: number;
  answer: boolean;
  comment?: string;
  image_url?: string;
}

interface Area {
  id: number;
  name: string;
}

interface Location {
  id: number;
  name: string;
  area_id: number;
}
interface AuditSubmissionResponse {
  auditId: number;
}

const api = axios.create({
  baseURL: config.apiUrl
});

const AuditExecution: React.FC = () => {
  const navigate = useNavigate();
  const [sections, setSections] = useState<Section[]>([]);
  const [responses, setResponses] = useState<AuditResponse[]>([]);
  const [overallComment, setOverallComment] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [areas, setAreas] = useState<Area[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [selectedAreaId, setSelectedAreaId] = useState<number | null>(null);
  const [selectedLocationId, setSelectedLocationId] = useState<number | null>(null);
  const [selectedQuarter, setSelectedQuarter] = useState<number | null>(null);
  const [disabledQuestions, setDisabledQuestions] = useState<Set<number>>(new Set());
  const [auditDate, setAuditDate] = useState<Date | null>(new Date());
  const [sectionImages, setSectionImages] = useState<{[key: number]: File[]}>({});
  const [compressedImages, setCompressedImages] = useState<{[key: number]: File}>({});
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [previewImages, setPreviewImages] = useState<{[key: number]: string[]}>({});
  const { user } = useAuth0();

  useEffect(() => {
      fetchSectionsAndQuestions();
      fetchAreas();
  }, []);

  const fetchSectionsAndQuestions = async () => {
      try {
        setIsLoading(true);
        const sectionsResponse = await api.get('/api/sections');
        const questionsResponse = await api.get('/api/questions');
        
        const sectionsWithQuestions = sectionsResponse.data.map((section: Section) => ({
          ...section,
          questions: questionsResponse.data.filter((q: Question) => q.section_id === section.id)
        }));
        
        setSections(sectionsWithQuestions);
        setResponses(questionsResponse.data.map((q: Question) => ({ question_id: q.id, answer: true })));
      } catch (error) {
        console.error('Error fetching sections and questions:', error);
        setError('Failed to fetch audit template. Please try again.');
      } finally {
        setIsLoading(false);
      }
  };

  const fetchAreas = async () => {
      try {
        const response = await api.get('/api/areas');
        setAreas(response.data);
      } catch (error) {
        console.error('Error fetching areas:', error);
        setError('Failed to fetch areas. Please try again.');
      }
  };

  const fetchLocations = async (areaId: number) => {
      try {
        const response = await api.get(`/api/areas/${areaId}/locations`);
        setLocations(response.data);
      } catch (error) {
        console.error('Error fetching locations:', error);
        setError('Failed to fetch locations. Please try again.');
      }
  };

  const handleAreaChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
      const areaId = parseInt(event.target.value);
      if (!isNaN(areaId)) {
          setSelectedAreaId(areaId);
          setSelectedLocationId(null);
          fetchLocations(areaId);
      } else {
          setSelectedAreaId(null);
          setSelectedLocationId(null);
          console.log("selectedAreaId is null");
      }
  };

  const handleLocationChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const locationId = parseInt(event.target.value);
    if (!isNaN(locationId)) {
        setSelectedLocationId(locationId);
    } else {
        setSelectedLocationId(null);
      console.log("selectedLocationId is null");  
    }
  };

  const handleQuarterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const quarter = parseInt(event.target.value);
    if (!isNaN(quarter)) {
        setSelectedQuarter(quarter);
    } else {
        setSelectedQuarter(null);
        console.log("selectedQuarter is null");
    }
  };

  const handleAnswerChange = (questionId: number, checked: boolean) => {
      setResponses(prev => 
        prev.map(r => r.question_id === questionId ? { ...r, answer: checked } : r)
      );
  };

  const handleCommentChange = (questionId: number, comment: string) => {
      setResponses(prev => 
        prev.map(r => r.question_id === questionId ? { ...r, comment } : r)
      );
  };

  const handleDateChange = (date: Date | null) => {
      setAuditDate(date);
  };

  const isFormValid = () => {
      return selectedAreaId !== null && selectedLocationId !== null && selectedQuarter !== null;
  };

  const handleSectionImageCompress = async (sectionId: number, files: FileList | null) => {
    if (!files || files.length === 0) {
      console.log('No files selected');
      return; // Exit the function early if no files were selected
    }
  
    try {
      const fileArray = Array.from(files);
      const compressedFiles = await compressImages(fileArray);
      
      const previewUrls = compressedFiles.map((file: File) => URL.createObjectURL(file));
      setPreviewImages(prev => ({
        ...prev,
        [sectionId]: [...(prev[sectionId] || []), ...previewUrls]
      }));
  
      setSectionImages(prev => ({
        ...prev,
        [sectionId]: [...(prev[sectionId] || []), ...compressedFiles]
      }));
    } catch (error) {
      console.error('Error compressing images:', error);
      // Instead of setting an error state, we'll just log it
      console.log('Failed to compress images. Please try again.');
    }
  };

  const removeSectionImage = (sectionId: number, index: number) => {
    setPreviewImages(prev => ({
      ...prev,
      [sectionId]: prev[sectionId].filter((_, i) => i !== index)
    }));
    setSectionImages(prev => ({
      ...prev,
      [sectionId]: prev[sectionId].filter((_, i) => i !== index)
    }));
  };

  const calculateSectionScore = (section: Section): number | null => {
    console.log('Calculating section score:', section);
    
    // Filter out disabled questions
    const activeQuestions = section.questions.filter(question => !disabledQuestions.has(question.id));
    
    // If all questions are disabled, return null
    if (activeQuestions.length === 0) {
      return null;
    }
  
    const positiveResponses = activeQuestions.reduce((count: number, question: Question) => {
      const response = responses.find(r => r.question_id === question.id);
      return count + (response && response.answer ? 1 : 0);
    }, 0);
  
    const sectionScore = (positiveResponses / activeQuestions.length) * 100;
    console.log('Section score:', sectionScore);
    return sectionScore;
  };

  const MAX_RETRIES = 3;
  const RETRY_DELAY = 2000; // 2 seconds

  const uploadImages = async (): Promise<Record<string, string[]>> => {
    const uploadedImages: Record<string, string[]> = {};
    const totalImages = Object.values(sectionImages).reduce((sum, images) => sum + images.length, 0);
    let uploadedCount = 0;
  
    for (const [sectionId, images] of Object.entries(sectionImages)) {
      const imageUrls = await Promise.all(images.map(image => uploadImage(image)));
      uploadedImages[sectionId] = imageUrls;
      uploadedCount += images.length;
      setUploadProgress((uploadedCount / totalImages) * 100);
    }
  
    console.log('Uploaded images:', uploadedImages);
    return uploadedImages;
  };

  const submitAuditData = async (uploadedImages: Record<string, string[]>, retryCount = 0): Promise<number> => {
    try {
      const updatedSections = sections
        .map(section => {
          const activeQuestions = section.questions.filter(question => !disabledQuestions.has(question.id));
          
          // If there are no active questions, return null
          if (activeQuestions.length === 0) {
            return null;
          }
  
          return {
            ...section,
            image_urls: uploadedImages[section.id.toString()] || [],
            score: calculateSectionScore(section),
            questions: activeQuestions // Only include active questions
          };
        })
        .filter(section => section !== null); // Remove null sections
  
      console.log('Sections with image URLs:', updatedSections);
  
      const filteredResponses = responses.filter(r => !disabledQuestions.has(r.question_id));
  
      const response = await api.post<AuditSubmissionResponse>('/api/submitAudit', {
        sections: updatedSections,
        responses: filteredResponses,
        overallComment,
        areaId: selectedAreaId,
        locationId: selectedLocationId,
        quarter: selectedQuarter,
        userId: user?.sub,
        auditDate: auditDate ? auditDate.toISOString() : null,
      });
  
      return response.data.auditId;
    } catch (error) {
      if (retryCount < MAX_RETRIES) {
        console.log(`Retry attempt ${retryCount + 1} for audit submission`);
        await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
        return submitAuditData(uploadedImages, retryCount + 1);
      } else {
        throw error;
      }
    }
  };

  const submitAudit = async () => {
    if (!isFormValid()) {
      setError('Please select an area and location');
      return;
    }

    if (!auditDate) {
      setError('Please select a date');
      return;
    }

    setIsUploading(true);
    setUploadProgress(0);
    setError(null);

    try {
      // Step 1: Upload images
      const uploadedImages = await uploadImages();
      setIsUploading(false);

      // Step 2: Submit audit data
      setIsSubmitting(true);
      const auditId = await submitAuditData(uploadedImages);

      // Step 3: Navigate to audit details page
      navigate(`/audit/details/${auditId}`);
    } catch (error) {
      console.error('Error submitting audit:', error);
      setError('Failed to submit audit. Please try again.');
    } finally {
      setIsUploading(false);
      setIsSubmitting(false);
    }
  };

  const handleDisableQuestion = (questionId: number) => {
    setDisabledQuestions(prev => {
      const newSet = new Set(prev);
      if (newSet.has(questionId)) {
        newSet.delete(questionId);
      } else {
        newSet.add(questionId);
      }
      return newSet;
    });
  };

  if (error) {
      return <div>Error: {error}</div>;
  }

  return (
    <div className="p-4 md:p-16">
      <div className="mb-4 flex flex-wrap justify-between items-center gap-2 lg:gap-4">
        <div className='w-full flex flex-row items-center justify-between lg:justify-normal gap-4'>
          <div className='flex flex-row items-center gap-2'>
            <p className="font-bold m-0">Auditor:</p>
            <p className='m-0'>{user?.name || 'Unknown'}</p>
          </div>
          <div className="w-full md:w-auto">
              <DatePicker
                selected={auditDate}
                onChange={handleDateChange}
                className="w-full border rounded px-3 py-2 text-center"
              />
          </div>
        </div>
        <div className='w-full flex flex-row flex-wrap lg:justify-normal gap-2 lg:gap-4'>
          <select 
            className="w-full lg:w-auto border rounded px-3 py-2"
            value={selectedQuarter || ''}
            onChange={handleQuarterChange}
            required
          >
            <option value="">Quarter</option>
            {[1, 2].map(quarter => (
                <option key={quarter} value={quarter}>Q{quarter}</option>
            ))}
          </select>
          <select 
            className="w-full lg:w-auto border rounded px-3 py-2"
            value={selectedAreaId || ''}
            onChange={handleAreaChange}
            required
          >
            <option value="">Select Area</option>
            {areas.map(area => (
              <option key={area.id} value={area.id}>{area.name}</option>
            ))}
          </select>
          <select 
            className="w-full lg:w-auto border rounded px-3 py-2"
            value={selectedLocationId || ''}
            onChange={handleLocationChange}
            disabled={!selectedAreaId}
            required
          >
            <option value="">Select Location</option>
            {locations.filter(loc => loc.area_id === selectedAreaId).map(location => (
              <option key={location.id} value={location.id}>{location.name}</option>
            ))}
          </select>
        </div>
      </div>
      {sections.map(section => (
        <div key={section.id} className="mb-8 bg-gray-100 p-2 md:p-4 rounded-lg" >
          <h3 className="text-2xl font-bold mb-2">{section.name}</h3>
          {section.description && (<p>{section.description}</p>)}

          {section.questions.map(question => (

            <div key={question.id} className={`mb-2 p-2 md:p-4 rounded ${disabledQuestions.has(question.id) ? 'bg-gray-200' : 'bg-white'}`}>
              {/* Question box */}
              <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-2">

                {/* toggle button and question text */}
                <div className="flex items-center justify-between gap-2 mt-2">

                  <label htmlFor={`toggle-${question.id}`} className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    id={`toggle-${question.id}`}
                    checked={responses.find(r => r.question_id === question.id)?.answer === true}
                    onChange={(e) => handleAnswerChange(question.id, e.target.checked)}
                    disabled={disabledQuestions.has(question.id)}
                    className="sr-only peer"
                  />
                  <div className={`w-14 h-7 ${
                    disabledQuestions.has(question.id)    
                      ? 'bg-gray-300 after:bg-gray-300 after:border-gray-400' 
                      : 'bg-gray-200 peer-focus:outline-none peer-checked:bg-blue-600 after:bg-white after:border-gray-300'
                    } rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:border after:rounded-full after:h-6 after:w-6 after:transition-all`}>
                  </div>
                  </label>
                  <p className={`font-semibold mb-0 ${  disabledQuestions.has(question.id) ? 'opacity-50' :   (responses.find(r => r.question_id === question.id)?.answer === false ? 'text-red-500' : '')}`}>{question.text}</p>
                </div>

                {/* disable button */}
                  <label className="hidden md:flex items-center cursor-pointer">
                    <div className="relative">
                      <input 
                        type="checkbox" 
                        className="sr-only"
                        checked={disabledQuestions.has(question.id)}
                        onChange={() => handleDisableQuestion(question.id)}
                      />
                      <div className="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                      <div className={`absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition ${disabledQuestions.has(question.id) ? 'transform translate-x-full bg-blue-500' : ''}`}></div>
                    </div>
                    <span className="ml-2 text-sm text-gray-700">Disable</span>
                  </label>
              </div>

              <div className={`flex flex-col md:flex-row md:items-center space-y-2 md:space-y-0 md:space-x-4 ${disabledQuestions.has(question.id) ? 'opacity-50' : ''}`}>
                {/* Comment input */}
                <div className="flex-grow mt-2 md:mt-0">
                  <input
                    type="text"
                    placeholder="Comment"
                    value={responses.find(r => r.question_id === question.id)?.comment || ''}
                    onChange={(e) => handleCommentChange(question.id, e.target.value)}
                    disabled={disabledQuestions.has(question.id)}
                    className="border rounded px-3 py-2 w-full"
                  />
                </div>
                {/* Disable button */}
                <div className="flex justify-end items-center mt-2 md:mt-0">
                  <label className="md:hidden flex items-center cursor-pointer ml-4">
                    <div className="relative">
                        <input 
                          type="checkbox" 
                          className="sr-only"
                          checked={disabledQuestions.has(question.id)}
                          onChange={() => handleDisableQuestion(question.id)}
                        />
                        <div className="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                        <div className={`absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition ${disabledQuestions.has(question.id) ? 'transform translate-x-full bg-blue-500' : ''}`}></div>
                    </div>
                    <span className="ml-2 text-sm text-gray-700">Disable</span>
                  </label>
                </div>
              </div>
            </div>
          ))}

          {/* Section image upload */}
          <div className="mb-2 flex space-x-2">
            <label className="cursor-pointer flex items-center bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
              <CameraIcon className="h-5 w-5" />
              <input
                type="file"
                accept="image/*"
                capture="environment"
                onChange={(e) => handleSectionImageCompress(section.id, e.target.files)}
                className="hidden"
                multiple
              />
            </label>
            <label className="cursor-pointer flex items-center gap-1 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded">
              <PhotoIcon className="h-5 w-5" />
              Upload photo(s)
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleSectionImageCompress(section.id, e.target.files)}
                className="hidden"
                multiple
              />
            </label>
          </div>
          <div className="flex flex-wrap gap-2 mt-2">
            {previewImages[section.id]?.map((previewUrl, index) => (
              <div key={index} className="relative inline-block">
                <img src={previewUrl} alt={`Section Preview ${index + 1}`} className="w-24 h-24 object-cover rounded" />
                <button
                  onClick={() => removeSectionImage(section.id, index)}
                  className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1"
                  >
                  <XCircleIcon className="h-5 w-5" />
                </button>
              </div>
            ))}
          </div></div>	
      ))}

      <div className="mb-4 md:mb-8">
        <h3 className="text-xl font-bold mb-2 md:mb-4">Overall Comment</h3>
        <textarea
          placeholder="Overall Comment"
          value={overallComment}
          onChange={(e) => setOverallComment(e.target.value)}
          className="border rounded px-3 py-2 w-full h-24 md:h-32"
        />
      </div>
      <button 
        onClick={submitAudit} 
        disabled={isUploading || isSubmitting || !isFormValid()}
        className={`font-bold py-2 px-4 mb-8 rounded ${
        isUploading || isSubmitting || !isFormValid()
        ? 'bg-gray-400 cursor-not-allowed'
        : 'bg-green-500 hover:bg-green-600 text-white'
        }`}
        >
        {isUploading ? 'Uploading...' : isSubmitting ? 'Submitting...' : 'Submit Audit'}
      </button>
      {/*isUploading && <ProgressBar progress={uploadProgress} label="Uploading images..." />*/}
      {/*isSubmitting && <ProgressBar progress={100} label="Submitting audit..." />*/}
    </div>
      
    
  );
};

export default AuditExecution;