import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Bars3Icon, TrashIcon, PlusCircleIcon } from '@heroicons/react/24/solid';
import config from '../config';
import useDebounce from '../hooks/useDebounce';

interface Area {
  id: number;
  name: string;
}

interface Location {
  id: number;
  area_id: number;
  name: string;
}

const api = axios.create({
  baseURL: config.apiUrl
});

const Settings: React.FC = () => {
  const [areas, setAreas] = useState<Area[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [newAreaName, setNewAreaName] = useState('');
  const [newLocationNames, setNewLocationNames] = useState<{ [key: number]: string }>({});
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  // New state for debounced area and location names
  const [debouncedAreaNames, setDebouncedAreaNames] = useState<{ [key: number]: string }>({});
  const [debouncedLocationNames, setDebouncedLocationNames] = useState<{ [key: number]: string }>({});

  // Debounce the area and location names
  const debouncedAreas = useDebounce(debouncedAreaNames, 500);
  const debouncedLocations = useDebounce(debouncedLocationNames, 500);

  const fetchAreas = async () => {
    setIsLoading(true);
    setError(null);
    try {
      console.log('Fetching areas...');
      const response = await api.get('/api/areas');
      console.log('Areas response:', response);

      if (response.status !== 200) {
        throw new Error(`Network response was not ok: ${response.status}`);
      }
      
      if (Array.isArray(response.data)) {
        console.log('Areas data:', response.data);
        setAreas(response.data);
        fetchLocationsForAreas(response.data);
      } else {
        throw new Error('Data is not an array');
      }
    } catch (err) {
      console.error('Error fetching areas:', err);
      setError('Failed to load areas. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAreas();
    // eslint-disable-next-line
  }, []);  // We're keeping this empty because fetchAreas is defined inside the component

  const fetchLocationsForAreas = async (areas: Area[]) => {
    try {
      console.log('Fetching locations for areas...');
      const locationsPromises = areas.map(area => 
        api.get(`/api/areas/${area.id}/locations`)
      );
      const locationsResponses = await Promise.all(locationsPromises);
      console.log('Locations responses:', locationsResponses);
      const allLocations = locationsResponses.flatMap(response => response.data);
      console.log('All locations:', allLocations);
      setLocations(allLocations);
    } catch (error) {
      console.error('Error fetching locations:', error);
      setError('Failed to load locations. Please try again.');
    }
  };

  const addArea = async () => {
    try {
      console.log('Adding new area:', newAreaName);
      const response = await api.post('/api/areas', { name: newAreaName });
      console.log('Add area response:', response);
      setAreas([...areas, response.data]);
      setNewAreaName('');
    } catch (error) {
      console.error('Error adding area:', error);
      setError('Failed to add area. Please try again.');
    }
  };

  const addLocation = async (areaId: number) => {
    const locationName = newLocationNames[areaId] || '';
    if (!locationName) return;

    try {
      console.log('Adding new location:', locationName, 'to area:', areaId);
      const response = await api.post('/api/locations', {
        area_id: areaId,
        name: locationName,
      });
      console.log('Add location response:', response);
      setLocations([...locations, response.data]);
      setNewLocationNames({ ...newLocationNames, [areaId]: '' });
    } catch (error) {
      console.error('Error adding location:', error);
      setError('Failed to add location. Please try again.');
    }
  };

  const updateArea = async (id: number, name: string) => {
    try {
      console.log('Updating area:', id, 'with name:', name);
      await api.put(`/api/areas/${id}`, { name });
      setAreas(areas.map(a => a.id === id ? { ...a, name } : a));
    } catch (error) {
      console.error('Error updating area:', error);
      setError('Failed to update area. Please try again.');
    }
  };

  const updateLocation = async (id: number, name: string) => {
    try {
      console.log('Updating location:', id, 'with name:', name);
      await api.put(`/api/locations/${id}`, { name });
      setLocations(locations.map(l => l.id === id ? { ...l, name } : l));
    } catch (error) {
      console.error('Error updating location:', error);
      setError('Failed to update location. Please try again.');
    }
  };

  const deleteArea = async (id: number) => {
    try {
      console.log('Deleting area:', id);
      await api.delete(`/api/areas/${id}`);
      setAreas(areas.filter(a => a.id !== id));
      setLocations(locations.filter(l => l.area_id !== id));
    } catch (error) {
      console.error('Error deleting area:', error);
      setError('Failed to delete area. Please try again.');
    }
  };

  const deleteLocation = async (id: number) => {
    try {
      console.log('Deleting location:', id);
      await api.delete(`/api/locations/${id}`);
      setLocations(locations.filter(l => l.id !== id));
    } catch (error) {
      console.error('Error deleting location:', error);
      setError('Failed to delete location. Please try again.');
    }
  };

  const onDragEnd = async (result: any) => {
    if (!result.destination) return;
  
    const sourceAreaId = parseInt(result.source.droppableId);
    const destinationAreaId = parseInt(result.destination.droppableId);
    const locationId = parseInt(result.draggableId);
  
    if (sourceAreaId !== destinationAreaId) {
      try {
        console.log('Moving location:', locationId, 'from area:', sourceAreaId, 'to area:', destinationAreaId);
        await api.put(`/api/locations/${locationId}`, { area_id: destinationAreaId });
        setLocations(locations.map(l => l.id === locationId ? { ...l, area_id: destinationAreaId } : l));
      } catch (error) {
        console.error('Error moving location:', error);
        setError('Failed to move location. Please try again.');
      }
    }
  };

  // Effect for debounced area names
  useEffect(() => {
    Object.entries(debouncedAreas).forEach(([id, name]) => {
      updateArea(parseInt(id), name);
    });
  }, [debouncedAreas]);

  // Effect for debounced location names
  useEffect(() => {
    Object.entries(debouncedLocations).forEach(([id, name]) => {
      updateLocation(parseInt(id), name);
    });
  }, [debouncedLocations]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="p-4 md:p-16">
      <div className="flex flex-row nowrap mb-8">
        <input
          type="text"
          value={newAreaName}
          onChange={(e) => setNewAreaName(e.target.value)}
          placeholder="New Area Name"
          className="flex w-full md:w-auto border rounded px-3 py-2 mr-2"
        />
        <button
          onClick={addArea}
          className="bg-blue-500 flex whitespace-nowrap hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
        >
          Add Area
        </button>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-4">
        {areas.length > 0 ? (
          areas.map(area => (
            <div key={area.id} className="bg-gray-100 p-4 rounded-lg">
              <div className="flex items-center mb-4">
                <input
                  type="text"
                  value={area.name}
                  onChange={(e) => {
                    setAreas(areas.map(a => a.id === area.id ? { ...a, name: e.target.value } : a));
                    setDebouncedAreaNames({ ...debouncedAreaNames, [area.id]: e.target.value });
                  }}
                  className="border rounded px-3 py-2 mr-2 flex-grow"
                />
                <TrashIcon
                  onClick={() => deleteArea(area.id)}
                  className="h-5 w-5 text-red-500 hover:text-red-600"
                />
              </div>
              <Droppable droppableId={area.id.toString()}>
                {(provided) => (
                  <ul {...provided.droppableProps} ref={provided.innerRef} className="mb-4 pl-2 md:pl-4">
                    {locations
                      .filter(location => location.area_id === area.id)
                      .map((location, index) => (
                        <Draggable key={location.id} draggableId={location.id.toString()} index={index}>
                          {(provided) => (
                            <li
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              className="mb-2 flex items-center"
                            >
                              <div
                                {...provided.dragHandleProps}
                                className="mr-2 cursor-move"
                              >
                                <Bars3Icon  className="h-5 w-5 text-gray-400" />
                              </div>
                              <input
                                type="text"
                                value={location.name}
                                onChange={(e) => {
                                  setLocations(locations.map(l => l.id === location.id ? { ...l, name: e.target.value } : l));
                                  setDebouncedLocationNames({ ...debouncedLocationNames, [location.id]: e.target.value });
                                }}
                                className="border rounded px-3 py-2 mr-2 flex-grow"
                              />
                              <TrashIcon
                                onClick={() => deleteLocation(location.id)}
                                className="h-5 w-5 text-gray-400 cursor-pointer flex-none"
                              />
                            </li>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
              <div className="flex items-center">
                <input
                  type="text"
                  value={newLocationNames[area.id] || ''}
                  onChange={(e) => setNewLocationNames({ ...newLocationNames, [area.id]: e.target.value })}
                  placeholder="New Location Name"
                  className="border rounded px-3 py-2 mr-2 flex-grow"
                />
                <PlusCircleIcon
                  onClick={() => addLocation(area.id)}
                  className="h-5 w-5 text-green-500 hover:text-green-600"
                />
              </div>
            </div>
          ))
        ) : (
          <p>No areas found. Please add an area.</p>
        )}
        </div>
      </DragDropContext>
    </div>
  );
};

export default Settings;
