import React from 'react';
import PropTypes from 'prop-types';
import WeeklyAveragesChart from './Report2WeeklyAveragesChart.js';
import MissingDaysTable from './Report2MissingDaysTable.js';
import SavedDataSection from './Report2SavedDataSection.js';

const Report2Content = ({
  data,
  selectedLocations,
  selectedEmployees,
  options,
  savedDataProps
}) => (
  <>
    {data && data.locationStats && Object.keys(data.locationStats).length > 0 && (
      <div className='bg-gray-100 p-6 rounded-lg border border-gray-200'>
        <WeeklyAveragesChart
          data={data}
          selectedLocations={selectedLocations}
          selectedEmployees={selectedEmployees}
          options={options}
        />
        <MissingDaysTable 
          data={data}
          selectedLocations={selectedLocations}
          selectedEmployees={selectedEmployees}
        />
      </div>
    )}
    <SavedDataSection
      {...savedDataProps}
      options={options}
    />
  </>
);

Report2Content.propTypes = {
  data: PropTypes.object,
  selectedLocations: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedEmployees: PropTypes.arrayOf(PropTypes.string).isRequired,
  options: PropTypes.object.isRequired,
  savedDataProps: PropTypes.shape({
    savedDataTitles: PropTypes.arrayOf(PropTypes.string).isRequired,
    setSavedDataTitles: PropTypes.func.isRequired,
    currentDataTitle: PropTypes.string.isRequired,
    setCurrentDataTitle: PropTypes.func.isRequired,
    consolidatedData: PropTypes.array.isRequired,
    setConsolidatedData: PropTypes.func.isRequired,
    handleSaveClickWrapper: PropTypes.func.isRequired,
    handleLoadDataWrapper: PropTypes.func.isRequired,
    handleDeleteDataWrapper: PropTypes.func.isRequired,
  }).isRequired,
};

export default React.memo(Report2Content);