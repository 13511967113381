import React from 'react';

const AreaSelect = ({ areas, selectedAreas, handleAreaChange }) => {
  return (
    <select
      multiple
      value={selectedAreas}
      onChange={handleAreaChange}
      className="p-2 border border-gray-300 rounded w-96"
    >
      {areas.map((area) => (
        <option key={area} value={area}>
          {area}
        </option>
      ))}
    </select>
  );
};

export default AreaSelect;