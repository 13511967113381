import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { BarChart, ComposedChart, Line, LabelList, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { getUnifiedColor } from '../../utils/helpers';
import config from '../../config';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { TrashIcon } from '@heroicons/react/24/solid';
import ConfirmationPopup from '../../components/ConfirmationPopup';

interface AuditSection {
  id: number;
  name: string;
  score: number;
}

interface Audit {
  id: number;
  date: string;
  score: string | null;
  area_name: string;
  quarter: number;
  location_name: string;
  sections: AuditSection[];
}

interface FilterOptions {
    area: string;
    location: string;
    startDate: string;
    endDate: string;
    startScore: string;
    endScore: string;
  }

  interface LayoutContext {
    isDarkMode: boolean;
  }

  interface SectionChartData {
    name: string;
    averageScore: number;
  }

const AUDITS_PER_PAGE = 20;

const api = axios.create({
  baseURL: config.apiUrl
});

const AuditHistory: React.FC = () => {
  const { isDarkMode } = useOutletContext<LayoutContext>();
  const [audits, setAudits] = useState<Audit[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    area: '',
    location: '',
    startDate: '',
    endDate: '',
    startScore: '',
    endScore: '100',
  });
  const [chartData, setChartData] = useState<any[]>([]);
  const [areas, setAreas] = useState<string[]>([]);
  const [locations, setLocations] = useState<string[]>([]);
  const [filteredAudits, setFilteredAudits] = useState<Audit[]>([]);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [auditToDelete, setAuditToDelete] = useState<number | null>(null);
  const [deletingAudits, setDeletingAudits] = useState<Set<number>>(new Set());
  const [sectionChartData, setSectionChartData] = useState<SectionChartData[]>([]);
  const [quarterlyChartData, setQuarterlyChartData] = useState<any[]>([]);

  const navigate = useNavigate();

  const fetchAuditHistory = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await api.get('/api/auditHistory');
      console.log('Audit history response:', response.data);
      if (Array.isArray(response.data)) {
        // Log the first audit to check its structure
        if (response.data.length > 0) {
          console.log('Sample audit:', response.data[0]);
        }
        setAudits(response.data);
      } else {
        throw new Error('Invalid data format received from server');
      }
    } catch (error) {
      console.error('Error fetching audit history:', error);
      setError('Failed to fetch audit history. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const applyFilters = useCallback(() => {
    let filtered = audits;

    if (filterOptions.area) {
      filtered = filtered.filter(audit => audit.area_name === filterOptions.area);
    }
    if (filterOptions.location) {
      filtered = filtered.filter(audit => audit.location_name === filterOptions.location);
    }
    if (filterOptions.startDate) {
        const startDate = new Date(filterOptions.startDate);
        filtered = filtered.filter(audit => new Date(audit.date) >= startDate);
    }
    if (filterOptions.endDate) {
      const endDate = new Date(filterOptions.endDate);
      const nextMonth = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 1);
      filtered = filtered.filter(audit => new Date(audit.date) < nextMonth);
    }
    if (filterOptions.startScore) {
      filtered = filtered.filter(audit => parseFloat(audit.score as string) >= parseFloat(filterOptions.startScore));
    }
    if (filterOptions.endScore) {
      filtered = filtered.filter(audit => parseFloat(audit.score as string) <= parseFloat(filterOptions.endScore));
    }

    setFilteredAudits(filtered);
    setCurrentPage(1);
  }, [audits, filterOptions]);

  const processDataForChart = useCallback(() => {
    // Group by month
    const groupedByMonth = filteredAudits.reduce((acc, audit) => {
      const month = new Date(audit.date).toLocaleString('default', { month: 'long', year: 'numeric' });
      if (!acc[month]) {
        acc[month] = [];
      }
      acc[month].push(audit);
      return acc;
    }, {} as Record<string, Audit[]>);
  
    // Get unique areas from filtered audits
    const uniqueAreas = Array.from(new Set(filteredAudits.map(audit => audit.area_name)));
  
    // Prepare chart data
    const chartData = Object.entries(groupedByMonth).map(([month, audits]) => {
      const result: Record<string, any> = { month };
  
      if (filterOptions.area === '') {
        // Calculate average for each area when 'All Areas' is selected
        uniqueAreas.forEach(area => {
          const areaAudits = audits.filter(audit => audit.area_name === area);
          const validScores = areaAudits.filter(audit => audit.score !== null && audit.score !== '');
          const avgScore = validScores.length > 0
            ? validScores.reduce((sum, audit) => sum + parseFloat(audit.score as string), 0) / validScores.length
            : null;
          result[area] = avgScore;
        });
      } else {
        // Show individual location scores when a specific area is selected
        const uniqueLocations = Array.from(new Set(audits.map(audit => audit.location_name)));
        uniqueLocations.forEach(location => {
          const locationAudits = audits.filter(audit => audit.location_name === location);
          const validScores = locationAudits.filter(audit => audit.score !== null && audit.score !== '');
          const avgScore = validScores.length > 0
            ? validScores.reduce((sum, audit) => sum + parseFloat(audit.score as string), 0) / validScores.length
            : null;
          result[location] = avgScore;
        });
      }
  
      const allValidScores = audits.filter(audit => audit.score !== null && audit.score !== '');
      const overallAvgScore = allValidScores.length > 0
        ? allValidScores.reduce((sum, audit) => sum + parseFloat(audit.score as string), 0) / allValidScores.length
        : null;
      result.avgScore = overallAvgScore;
  
      return result;
    });
  
    // Sort chart data by month
    chartData.sort((a, b) => new Date(a.month).getTime() - new Date(b.month).getTime());
  
    console.log('Processed chart data:', chartData);
    setChartData(chartData);
  }, [filteredAudits, filterOptions.area]);

  const processDataForQuarterlyChart = useCallback(() => {
    const quarterlyData: Record<string, Record<string, number[]>> = {};
  
    filteredAudits.forEach(audit => {
      const year = new Date(audit.date).getFullYear();
      const key = `Q${audit.quarter} ${year}`;
  
      if (!quarterlyData[key]) {
        quarterlyData[key] = {};
      }
  
      if (filterOptions.area === '') {
        // When 'All Areas' is selected, group by area
        if (!quarterlyData[key][audit.area_name]) {
          quarterlyData[key][audit.area_name] = [];
        }
        quarterlyData[key][audit.area_name].push(parseFloat(audit.score as string));
      } else {
        // When a specific area is selected, show location scores
        if (!quarterlyData[key][audit.location_name]) {
          quarterlyData[key][audit.location_name] = [];
        }
        quarterlyData[key][audit.location_name].push(parseFloat(audit.score as string));
      }
    });
  
    const chartData = Object.entries(quarterlyData).map(([quarter, entities]) => {
      const result: Record<string, any> = { quarter };
      Object.entries(entities).forEach(([entity, scores]) => {
        result[entity] = scores.reduce((sum, score) => sum + score, 0) / scores.length;
      });
      return result;
    });
  
    chartData.sort((a, b) => {
      const [aQ, aY] = a.quarter.split(' ');
      const [bQ, bY] = b.quarter.split(' ');
      return aY === bY ? aQ.localeCompare(bQ) : parseInt(aY) - parseInt(bY);
    });
  
    return chartData;
  }, [filteredAudits, filterOptions.area]);

  const setDefaultDateRange = useCallback(() => {
    const currentDate = new Date();
    const startOfYear = new Date(Date.UTC(currentDate.getFullYear(), 0, 1));
    setFilterOptions(prev => ({
      ...prev,
      startDate: startOfYear.toISOString().slice(0, 7),
      endDate: currentDate.toISOString().slice(0, 7),
      startScore: '0',
      endScore: '100'
    }));
  }, []);

  useEffect(() => {
    fetchAuditHistory();
    setDefaultDateRange();
  }, [fetchAuditHistory, setDefaultDateRange]);

  useEffect(() => {
    const uniqueAreas = [...new Set(audits.map(audit => audit.area_name))];
    setAreas(uniqueAreas);
    updateLocations(filterOptions.area);
  }, [audits]);

  useEffect(() => {
    applyFilters();
  }, [audits, filterOptions, applyFilters]);

  const updateLocations = useCallback((selectedArea: string) => {
    let filteredLocations;
    if (selectedArea === '') {
      filteredLocations = [...new Set(audits.map(audit => audit.location_name))];
    } else {
      filteredLocations = [...new Set(audits.filter(audit => audit.area_name === selectedArea).map(audit => audit.location_name))];
    }
    setLocations(filteredLocations);
  }, [audits]);
    
  const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const { name, value } = event.target;
    setFilterOptions(prev => {
      let newOptions = { ...prev, [name]: value };
  
      if (name === 'startDate') {
        const startDate = new Date(value);
        const endDate = new Date(prev.endDate);
        if (startDate > endDate) {
          // Set endDate to the last day of the startDate's month
          newOptions.endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0).toISOString().slice(0, 7);
        }
      } else if (name === 'endDate') {
        const startDate = new Date(prev.startDate);
        const endDate = new Date(value);
        if (endDate < startDate) {
          // Set startDate to the first day of the endDate's month
          newOptions.startDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1).toISOString().slice(0, 7);
        }
      } else if (name === 'area') {
        updateLocations(value);
        newOptions.location = '';
      }
  
      return newOptions;
    });
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
  };

  const handleAuditClick = (id: number) => {
    navigate(`/audit/details/${id}`);
  };

  const deleteAudit = async (id: number) => {
    try {
      setDeletingAudits(prev => new Set(prev).add(id));
      await api.delete(`/api/audits/${id}`);
      setAudits(audits.filter(audit => audit.id !== id));
      setFilteredAudits(filteredAudits.filter(audit => audit.id !== id));
    } catch (error) {
      console.error('Error deleting audit:', error);
      // Show an error message to the user here
      setDeletingAudits(prev => {
        const newSet = new Set(prev);
        newSet.delete(id);
        return newSet;
      });
    }
  };

  const handleDeleteClick = (id: number) => {
    setAuditToDelete(id);
    setIsDeletePopupOpen(true);
  };

  const handleConfirmDelete = () => {
    if (auditToDelete) {
      setIsDeletePopupOpen(false);
      deleteAudit(auditToDelete);
    }
  };

  const processSectionData = useCallback((): SectionChartData[] => {
  const sectionScores: { [key: string]: number[] } = {};

  filteredAudits.forEach(audit => {
    if (audit.sections && Array.isArray(audit.sections)) {
      audit.sections.forEach(section => {
        if (!sectionScores[section.name]) {
          sectionScores[section.name] = [];
        }
        sectionScores[section.name].push(section.score);
      });
    }
  });

  const averageSectionScores = Object.entries(sectionScores).map(([name, scores]) => ({
    name,
    averageScore: scores.length > 0 
      ? scores.reduce((sum, score) => sum + score, 0) / scores.length
      : 0
  }));

  return averageSectionScores;
}, [filteredAudits]);

// Effect for chart data
useEffect(() => {
  processDataForChart();
  const newSectionChartData = processSectionData();
  console.log('Section chart data:', newSectionChartData);
  setSectionChartData(newSectionChartData);

  const newQuarterlyChartData = processDataForQuarterlyChart();
  console.log('Quarterly chart data:', newQuarterlyChartData);
  setQuarterlyChartData(newQuarterlyChartData);
}, [filteredAudits, processDataForChart, processSectionData, processDataForQuarterlyChart]);

  const SectionScoreChart: React.FC<{ data: SectionChartData[], isDarkMode: boolean }> = ({ data, isDarkMode }) => (
    <div className="overflow-x-auto custom-scroll">
      <div className={`rounded-lg p-4 mb-8 min-w-[600px] h-[600px] ${isDarkMode ? 'bg-gray-600' : 'bg-gray-100'}`}>
      <ResponsiveContainer>
        <BarChart 
          data={data}
          margin={{ top: 30, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            dataKey="name" 
            textAnchor="end"
            angle={-35} 
            height={200} />
          <YAxis domain={[0, 100]} ticks={[0, 20, 40, 60, 80, 100]} />
          <Tooltip 
            contentStyle={isDarkMode ? { backgroundColor: '#333', color: '#fff' } : undefined}
            formatter={(value: number) => [`${value.toFixed(2)}%`, 'Average Score']}
          />
          
          <Bar dataKey="averageScore" fill="#8884d8">
            <LabelList dataKey="averageScore" position="top" formatter={(value: number) => `${value.toFixed(0)}%`} style={{ fontSize: '12px' }} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div></div>
  );

  const QuarterlyChart: React.FC<{ data: any[], isDarkMode: boolean }> = ({ data, isDarkMode }) => {
    const locations = Array.from(new Set(data.flatMap(Object.keys).filter(key => key !== 'quarter' && key !== 'averageScore')));
  
    // Calculate average score for each quarter
    const dataWithAverage = data.map(quarter => {
      const scores = locations.map(loc => quarter[loc] || 0).filter(score => score > 0);
      const averageScore = scores.length > 0 ? scores.reduce((sum, score) => sum + score, 0) / scores.length : 0;
      return { ...quarter, averageScore };
    });
  
    return (
      <div className="overflow-x-auto custom-scroll">
      <div className={`flex rounded-lg p-4 mb-8 min-w-[600px] h-[600px] ${isDarkMode ? 'bg-gray-600' : 'bg-gray-100'}`}>
        
        <ResponsiveContainer>
          <ComposedChart data={dataWithAverage} margin={{ top: 30, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="quarter" />
            <YAxis yAxisId="left" domain={[0, 100]} />
            <YAxis yAxisId="right" orientation="right" domain={[0, 100]} />
            <Tooltip 
              contentStyle={isDarkMode ? { backgroundColor: '#333', color: '#fff' } : undefined}
              formatter={(value: number, name: string) => [`${value.toFixed(2)}%`, name]}
            />
            <Legend />
            {locations.map((location, index) => (
              <Bar 
                key={location} 
                dataKey={location} 
                fill={getUnifiedColor(location)} 
                yAxisId="left"
                isAnimationActive={false}
              >
                <LabelList dataKey={location} position="top" formatter={(value: number) => `${value.toFixed(0)}%`} style={{ fontSize: '12px' }} />
              </Bar>
            ))}
            <Line
              type="monotone"
              dataKey="averageScore"
              name="Average"
              stroke="#ff7300"
              yAxisId="right"
              isAnimationActive={false}
              dot={{ r: 5 }}
              activeDot={{ r: 8 }}
            >
              
            </Line>
          </ComposedChart>
        </ResponsiveContainer>
        </div>
      </div>
    );
  };

  const LocationChart: React.FC<{ data: any[], isDarkMode: boolean }> = ({ data, isDarkMode }) => {
    const locations = Array.from(new Set(data.flatMap(Object.keys).filter(key => key !== 'quarter' && key !== 'averageScore')));
      return (
        <div className={`rounded-lg p-4 mb-8 ${isDarkMode ? 'bg-gray-600' : 'bg-gray-100'}`} style={{ width: '100%', height: 600 }}>
          <ResponsiveContainer>
            <ComposedChart data={chartData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis yAxisId="left" />
                    <YAxis yAxisId="right" orientation="right" />
                    <Tooltip contentStyle={isDarkMode ? { backgroundColor: '#333', color: '#fff' } : undefined} />
                    <Legend />
                    {filterOptions.area === '' ? (
                      // Show area averages when 'All Areas' is selected
                      areas.map((area, index) => (
                        <Bar
                          key={area}
                          dataKey={area}
                          fill={getUnifiedColor(area)}
                          yAxisId="left"
                          isAnimationActive={false}
                        />
                      ))
                    ) : (
                      // Show location scores when a specific area is selected
                      Array.from(new Set(filteredAudits.map(audit => audit.location_name))).map((location, index) => (
                        <Bar
                          key={location}
                          dataKey={location}
                          fill={getUnifiedColor(location)}
                          yAxisId="left"
                          isAnimationActive={false}
                        />
                      ))
                    )}
                    <Line 
                    type="monotone" 
                    name="Average"
                    dataKey="avgScore" 
                    stroke="#ff7300" 
                    yAxisId="right"
                    isAnimationActive={false}
                    />
                </ComposedChart>
            </ResponsiveContainer>
          </div>
      );  
};

  const totalPages = Math.ceil(filteredAudits.length / AUDITS_PER_PAGE);
  const paginatedAudits = filteredAudits.slice(
    (currentPage - 1) * AUDITS_PER_PAGE,
    currentPage * AUDITS_PER_PAGE
  );

  return (
    <div className={`p-4 md:p-16 ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-black'}`}>
      {isLoading ? (
        <p>Loading audit history...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : audits.length === 0 ? (
        <p>No audit history available.</p>
      ) : (
        <>
          <div className="text-center overflow-x-auto">
                {/* Filter UI */}
                <div className="mb-4 flex flex-row flex-wrap gap-4 md:gap-4">
                    <div className="flex flex-nowrap gap-2 md:gap-4 items-center">
                      <select 
                          name="area" 
                          onChange={handleFilterChange} 
                          value={filterOptions.area}
                          className={`w-full md:w-auto flex px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${
                                      isDarkMode ? 'bg-gray-600 text-white border-gray-500' : 'bg-white text-black border-gray-300'
                                    }`}
                      >
                          <option value="">All Areas</option>
                          {areas.map(area => (
                          <option key={area} value={area}>{area}</option>
                          ))}
                      </select>
                      
                      <select 
                          name="location" 
                          onChange={handleFilterChange} 
                          value={filterOptions.location}
                          className={`w-full md:w-auto px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${
                            isDarkMode ? 'bg-gray-600 text-white border-gray-500' : 'bg-white text-black border-gray-300'
                          }`}
                      >
                          <option value="">All Locations</option>
                          {locations.map(location => (
                          <option key={location} value={location}>{location}</option>
                          ))}
                      </select>
                    </div>
                    <div className="flex flex-wrap gap-4 md:gap-4">
                      <div className="flex lg:w-auto gap-2 items-center">
                        <DatePicker
                          selected={filterOptions.startDate ? new Date(filterOptions.startDate) : null}
                          onChange={(date: Date | null) => {
                            if (date) {
                              handleFilterChange({
                                target: { name: 'startDate', value: date.toISOString().slice(0, 7) }
                              } as React.ChangeEvent<HTMLInputElement>);
                            } else {
                              handleFilterChange({
                                target: { name: 'startDate', value: '' }
                              } as React.ChangeEvent<HTMLInputElement>);
                            }
                          }}
                          dateFormat="MMM yyyy"
                          showMonthYearPicker
                          className={`w-32 px-4 py-2 border rounded-md shadow-sm focus:outline-none ${
                            isDarkMode ? 'bg-gray-600 text-white border-gray-500' : 'bg-white text-black border-gray-300'
                          }`}
                        />
                        <p className="m-0">-</p>
                        <DatePicker
                          selected={filterOptions.endDate ? new Date(filterOptions.endDate) : null}
                          onChange={(date: Date | null) => {
                            if (date) {
                              handleFilterChange({
                                target: { name: 'endDate', value: date.toISOString().slice(0, 7) }
                              } as React.ChangeEvent<HTMLInputElement>);
                            } else {
                              handleFilterChange({
                                target: { name: 'endDate', value: '' }
                              } as React.ChangeEvent<HTMLInputElement>);
                            }
                          }}
                          dateFormat="MMM yyyy"
                          showMonthYearPicker
                          className={`w-32 px-4 py-2 border rounded-md shadow-sm focus:outline-none ${
                            isDarkMode ? 'bg-gray-600 text-white border-gray-500' : 'bg-white text-black border-gray-300'
                          }`}
                        />
                      </div>
                      <div className="flex flex-wrap gap-2">
                        <select
                            name="startScore"
                            onChange={handleFilterChange}
                            value={filterOptions.startScore}
                            className={`w-32 md:w-auto px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${
                              isDarkMode ? 'bg-gray-600 text-white border-gray-500' : 'bg-white text-black border-gray-300'
                            }`}
                        >
                            
                            {[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map(score => (
                                <option key={score} value={score}>{score} %</option>
                            ))}
                        </select>  
                        <p className="m-0 flex items-center">-</p>
                        <select
                            name="endScore"
                            onChange={handleFilterChange}
                            value={filterOptions.endScore}
                            className={`w-32 md:w-auto px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${
                              isDarkMode ? 'bg-gray-600 text-white border-gray-500' : 'bg-white text-black border-gray-300'
                            }`}
                        >
                            {[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map(score => (
                                <option key={score} value={score}>{score} %</option>
                            ))}
                        </select>
                      </div>
                    </div>
                </div>

                {/* Chart 
                {Array.isArray(chartData) && chartData.length > 0 ? (
                  <LocationChart data={chartData} isDarkMode={isDarkMode} />
                
                ) : (
                    <p>No chart data available.</p>
                )}*/}

                {/* New Quarterly Chart */}
                {quarterlyChartData.length > 0 ? (
                  <QuarterlyChart data={quarterlyChartData} isDarkMode={isDarkMode} />
                ) : (
                  <p>No quarterly chart data available.</p>
                )}

                {/* New Section Score Chart */}
                  {sectionChartData.length > 0 ? (
                    <SectionScoreChart data={sectionChartData} isDarkMode={isDarkMode} />
                  ) : (
                    <p>No section score data available. This may be because the audits don't have section data or all sections have been filtered out.</p>
                  )}

                {filteredAudits.length === 0 ? (
                  <p>No audits match the current filters.</p>
                ) : (
                  <div className={`rounded-lg p-4 mb-8 ${isDarkMode ? 'bg-gray-600' : 'bg-gray-100'}`}>
                    <div className=' overflow-x-auto custom-scroll'>
                      <table className="table-auto w-full">
                      <thead>
                          <tr className={`${isDarkMode ? 'bg-gray-600' : 'bg-gray-200'}`}>
                          <th className="px-4 py-2 text-left whitespace-nowrap">Date</th>
                          <th className="px-4 py-2 text-left whitespace-nowrap">Score</th>
                          <th className="px-4 py-2 text-left">Location</th>
                          <th className="px-4 py-2 text-center"></th>
                          </tr>
                      </thead>
                      <tbody>
                      {paginatedAudits.map((audit) => (
                        !deletingAudits.has(audit.id) && (
                          <tr
                            key={audit.id}
                            onClick={() => handleAuditClick(audit.id)}
                            className={`cursor-pointer ${
                              isDarkMode ? 'bg-gray-700 hover:bg-gray-600' : 'bg-white hover:bg-gray-200'
                            }`}
                          >
                            <td className="border px-4 py-2 text-left max-w-32 lg:max-w-8 whitespace-nowrap">{formatDate(audit.date)}</td>
                            <td className="border px-4 py-2 text-left max-w-32 lg:max-w-8  whitespace-nowrap">{audit.score} %</td>
                            <td className="border px-4 py-2 text-left">{audit.location_name}</td>
                            <td className="border px-4 py-2 text-center">
                              <TrashIcon
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDeleteClick(audit.id);
                                }}
                                className="h-5 w-5 text-red-500 hover:text-red-600"
                              />
                            </td>
                          </tr>
                        )
                      ))}
                      </tbody>
                      </table>
                    </div>
                  </div>
                )}
            </div>
          <div className="flex px-4 justify-between items-center">
            <button
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
              className={`font-bold py-2 px-4 rounded disabled:opacity-50 ${
                isDarkMode
                  ? 'bg-blue-600 hover:bg-blue-700 text-white'
                  : 'bg-blue-500 hover:bg-blue-700 text-white'
              }`}
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
              className={`font-bold py-2 px-4 rounded disabled:opacity-50 ${
                isDarkMode
                  ? 'bg-blue-600 hover:bg-blue-700 text-white'
                  : 'bg-blue-500 hover:bg-blue-700 text-white'
              }`}
            >
              Next
            </button>
          </div>
        </>
      )}
      <ConfirmationPopup
        isOpen={isDeletePopupOpen}
        onClose={() => setIsDeletePopupOpen(false)}
        onConfirm={handleConfirmDelete}
        message="Are you sure you want to delete this audit? This action cannot be undone."
        isDarkMode={isDarkMode}
      />
    </div>
  );
};

export default AuditHistory;