import { useMemo } from 'react';

const useFilteredEmployees = (data, selectedLocations) => {
  return useMemo(() => {
    if (!data || !data.locationStats) return [];

    return Array.from(new Set(
      Object.entries(data.locationStats)
        .filter(([location, _]) => selectedLocations.includes(location))
        .flatMap(([_, locationData]) => 
          Object.values(locationData).flatMap(weekData => 
            Object.values(weekData).flatMap(dayData => 
              Object.keys(dayData)
            )
          )
        )
    ));
  }, [data, selectedLocations]);
};

export default useFilteredEmployees;