/**
 * Configuration file for API URL
 * 
 * This file provides a centralized configuration for the API URL used throughout the application.
 * It automatically switches between development and production URLs based on the environment.
 * 
 * In development: It uses the full URL of the local development server.
 * In production: It uses an empty string, which results in relative URLs being used.
 *                This allows the frontend to make requests to the same domain it's hosted on.
 * 
 * Usage:
 * 1. Import this config in your component or service file:
 *    import config from '../path/to/config';
 * 
 * 2. Use the apiUrl when making API calls:
 *    axios.get(`${config.apiUrl}/api/endpoint`)
 */

const config = {
  apiUrl: process.env.NODE_ENV === 'production'
    ? '' // Empty string for production, as we'll use relative URLs
    : 'http://localhost:3000' // Development server URL
};

export default config;