import React from 'react';

const EmployeeSelect = ({ employees, selectedEmployees, handleEmployeeChange }) => {

  if (employees.length === 0) {
    //console.log("Employee List is empty. Check the source data and ensure employees are being correctly fetched.");
  } else {
    //console.log("Employee List:", employees);
    console.log("Selected Employees:", selectedEmployees);
  }

  return (
    <select
      multiple
      value={selectedEmployees}
      onChange={handleEmployeeChange}
      className="p-2 border border-gray-300 rounded w-96"
    >
      {employees.map((employee, index) => (
        <option key={index} value={employee}>
          {employee}
        </option>
      ))}
    </select>
  );
};

export default EmployeeSelect;