import React from 'react';
import { BarChart as RechartsBarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { getUnifiedColor, formatNumber } from '../utils/helpers.js';

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
          <p className="label">{`${label}`}</p>
          {payload.map((entry, index) => (
            <p key={`item-${index}`}>
              {`${entry.name}: kr ${formatNumber(entry.value)}`}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };
  
const BarChart = ({ data, dataKey, xAxisDataKey, years, selectedTime, maxBarSize = 80 }) => {
    return (
        <ResponsiveContainer width="100%" height={500}>
            <RechartsBarChart data={data}>
                <CartesianGrid strokeDasharray="1 3" />
                <XAxis
                    dataKey={xAxisDataKey}
                    interval={0}
                    angle={-35}
                    textAnchor="end"
                    height={130}
                />
                <YAxis 
                        tickFormatter={(value) => (value / 1000000).toFixed(2) + 'M'}
                        width={80} />
                <Tooltip content={<CustomTooltip />} />
                <Legend />
                {selectedTime === 'all'
                    ? years.map((year) => (
                        <Bar
                            key={year}
                            dataKey={year}
                            name={year}
                            fill={getUnifiedColor(year)}
                            maxBarSize={maxBarSize}
                        />
                    ))
                    : (() => {
                        const [year, month] = selectedTime.split('-');
                        const label = month ? `${month}` : year;
                        return <Bar dataKey={dataKey} name={label} fill={getUnifiedColor(year)} maxBarSize={maxBarSize} />;
                    })()
                }
            </RechartsBarChart>
        </ResponsiveContainer>
    );
};

export default BarChart;