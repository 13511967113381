import React from 'react';
import { Table } from 'react-bootstrap';
import { formatNumber } from '../utils/helpers.js';

const SummaryTable = ({ summaryData, selectedAreas }) => {
    if (!summaryData) return <p>No data available</p>;
    //console.log('SummaryTable - Props:', { summaryData, selectedAreas });

    const renderTopPT = (topPT) => {
        //console.log('Top PT:', topPT);
        if (!topPT || !topPT.full_name) return 'N/A';
        return `${topPT.full_name} (kr ${formatNumber(topPT.total_revenue)})`;
    };

    const renderTopLocation = (topLocation) => {
        if (!topLocation || !topLocation.location) return 'N/A';
        return `${topLocation.location} (kr ${formatNumber(topLocation.revenue)})`;
    };

    const renderTopArea = (topArea) => {
        if (!topArea || !topArea.area) return 'N/A';
        return `${topArea.area} (kr ${formatNumber(topArea.revenue)})`;
    };

    if (summaryData.type === 'multi-year') {
        return (
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Metric</th>
                        {summaryData.years.map(year => (
                            <th key={year}>{year}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Total Sales</td>
                        {summaryData.years.map(year => (
                            <td key={year}>kr {formatNumber(summaryData.yearlyData[year].totalSales)}</td>
                        ))}
                    </tr>
                    <tr>
                        <td>Average Income per PT</td>
                        {summaryData.years.map(year => (
                            <td key={year}>kr {formatNumber(summaryData.yearlyData[year].avgIncome)}</td>
                        ))}
                    </tr>
                    <tr>
                        <td>Number of Active PTs</td>
                        {summaryData.years.map(year => (
                            <td key={year}>{summaryData.yearlyData[year].activePTs}</td>
                        ))}
                    </tr>
                    <tr>
                        <td>Top Performing PT</td>
                        {summaryData.years.map(year => (
                            <td key={year}>{renderTopPT(summaryData.yearlyData[year].topPT)}</td>
                        ))}
                    </tr>
                    <tr>
                        <td>Top Performing Location</td>
                        {summaryData.years.map(year => (
                            <td key={year}>{renderTopLocation(summaryData.yearlyData[year].topLocation)}</td>
                        ))}
                    </tr>
                    {selectedAreas.length > 1 && (
                        <tr>
                            <td>Top Performing Area</td>
                            {summaryData.years.map(year => (
                                <td key={year}>{renderTopArea(summaryData.yearlyData[year].topArea)}</td>
                            ))}
                        </tr>
                    )}
                    <tr>
                        <td>Total Hours Worked</td>
                        {summaryData.years.map(year => (
                            <td key={year}>{formatNumber(summaryData.yearlyData[year].totalHours).split(',')[0]} hours</td>
                        ))}
                    </tr>
                    <tr>
                        <td>Average Revenue per Hour</td>
                        {summaryData.years.map(year => (
                            <td key={year}>kr {formatNumber(summaryData.yearlyData[year].avgRevenuePerHour)}</td>
                        ))}
                    </tr>
                </tbody>
            </Table>
        );
    } else {
        return (
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Metric</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Total Sales</td>
                        <td>kr {formatNumber(summaryData.totalSales)}</td>
                    </tr>
                    <tr>
                        <td>Average Income per PT</td>
                        <td>kr {formatNumber(summaryData.avgIncome)}</td>
                    </tr>
                    <tr>
                        <td>Number of Active PTs</td>
                        <td>{summaryData.activePTs}</td>
                    </tr>
                    <tr>
                        <td>Top Performing PT</td>
                        <td>{renderTopPT(summaryData.topPT)}</td>
                    </tr>
                    <tr>
                        <td>Top Performing Location</td>
                        <td>{renderTopLocation(summaryData.topLocation)}</td>
                    </tr>
                    {selectedAreas.length > 1 && (
                        <tr>
                            <td>Top Performing Area</td>
                            <td>{renderTopArea(summaryData.topArea)}</td>
                        </tr>
                    )}
                    <tr>
                        <td>Total Hours Worked</td>
                        <td>{formatNumber(summaryData.totalHours).split(',')[0]}</td>
                    </tr>
                    <tr>
                        <td>Average Revenue per Hour</td>
                        <td>kr {formatNumber(summaryData.avgRevenuePerHour)}</td>
                    </tr>
                </tbody>
            </Table>
        );
    }
};

export default SummaryTable;