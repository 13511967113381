import React from 'react';
import PropTypes from 'prop-types';
import LocationSelect from './LocationSelect.js';
import EmployeeSelect from './EmployeeSelect.js';

const Report2FilterControls = ({
  locations,
  selectedLocations,
  handleLocationChange,
  employees,
  selectedEmployees,
  handleEmployeeChange
}) => (
  <div className="flex justify-center mb-8 space-x-4">
    <LocationSelect
      locations={locations}
      selectedLocations={selectedLocations}
      handleLocationChange={handleLocationChange}
    />
    <EmployeeSelect
      employees={employees}
      selectedEmployees={selectedEmployees}
      handleEmployeeChange={handleEmployeeChange}
    />
  </div>
);

Report2FilterControls.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedLocations: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleLocationChange: PropTypes.func.isRequired,
  employees: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedEmployees: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleEmployeeChange: PropTypes.func.isRequired
};

export default React.memo(Report2FilterControls);