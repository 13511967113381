import React from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import AuditTemplate from './audit/AuditTemplate';
import AuditExecution from './audit/AuditExecution';
import AuditHistory from './audit/AuditHistory';
import AuditDetails from './audit/AuditDetails';
import Settings from './Settings';



const AuditDashboard: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
  
    const handleNavigationChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
      navigate(event.target.value);
    };
  
    return (
            <div className="max-w-screen-2xl">
              <Routes>
                <Route path="/settings" element={<Settings />} />
                <Route path="/template" element={<AuditTemplate />} />
                <Route path="/execute" element={<AuditExecution />} />
                <Route path="/history" element={<AuditHistory />} />
                <Route path="/details/:id" element={<AuditDetails />} />
                <Route
                  path="/"
                  element={
                    <div className="text-center text-gray-600">
                      Please select an audit option from the sidebar or dropdown above
                    </div>
                  }
                />
              </Routes>
            </div>
    );
  };

export default AuditDashboard;