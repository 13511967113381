import React, { useState, useEffect, useCallback  } from 'react';
import axios from 'axios';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Bars3Icon, TrashIcon, PlusCircleIcon } from '@heroicons/react/24/solid';
import useDebounce from '../../hooks/useDebounce';

interface Section {
  id: number;
  name: string;
  order_index: number;
  description: string;
}

interface Question {
  id: number;
  section_id: number;
  text: string;
  order_index: number;
}

const AuditTemplate: React.FC = () => {
  const [sections, setSections] = useState<Section[]>([]);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [newSectionName, setNewSectionName] = useState('');
  const [newQuestionTexts, setNewQuestionTexts] = useState<{ [key: number]: string }>({});

  const [changedQuestionText, setChangedQuestionText] = useState<{ id: number; text: string } | null>(null);
  const [changedSectionData, setChangedSectionData] = useState<{ id: number; name?: string; description?: string } | null>(null);

  const debouncedQuestionText = useDebounce(changedQuestionText, 10000);
  const debouncedSectionData = useDebounce(changedSectionData, 1000);

  useEffect(() => {
    fetchTemplate();
  }, []);

  const fetchTemplate = async () => {
    try {
      const sectionsResponse = await axios.get('/api/sections?templateId=1');
      const questionsResponse = await axios.get('/api/questions?templateId=1');
      setSections(sectionsResponse.data);
      setQuestions(questionsResponse.data);
    } catch (error) {
      console.error('Error fetching template:', error);
    }
  };

  const addSection = async () => {
    try {
      const response = await axios.post('/api/sections', { name: newSectionName });
      setSections([...sections, response.data]);
      setNewSectionName('');
    } catch (error) {
      console.error('Error adding section:', error);
    }
  };


  const addQuestion = async (sectionId: number) => {
    const questionText = newQuestionTexts[sectionId] || '';
    if (!questionText) return;

    try {
      const response = await axios.post('/api/questions', {
        section_id: sectionId,
        text: questionText,
      });
      setQuestions([...questions, response.data]);
      setNewQuestionTexts({ ...newQuestionTexts, [sectionId]: '' });
    } catch (error) {
      console.error('Error adding question:', error);
    }
  };

  const updateSection = useCallback(async (id: number, data: { name?: string; description?: string }) => {
    try {
      console.log('Updating section:', id, data);
      const response = await axios.put(`/api/sections/${id}`, data);
      console.log('Update response:', response.data);
  
      setSections((prevSections) =>
        prevSections.map((s) => (s.id === id ? { ...s, ...data } : s))
      );
    } catch (error) {
      console.error('Error updating section:', error);
    }
  }, []);

  const updateQuestion = useCallback(async (id: number, text: string) => {
    try {
      await axios.put(`/api/questions/${id}`, { text });
      setQuestions(questions.map(q => q.id === id ? { ...q, text } : q));
    } catch (error) {
      console.error('Error updating question:', error);
    }
  }, [questions]);

  useEffect(() => {
    if (debouncedSectionData) {
      const { id, ...data } = debouncedSectionData;
      updateSection(id, data);
    }
  }, [debouncedSectionData, updateSection]);

  useEffect(() => {
    if (debouncedQuestionText) {
      updateQuestion(debouncedQuestionText.id, debouncedQuestionText.text);
    }
  }, [debouncedQuestionText, updateQuestion]);

  const deleteSection = async (id: number) => {
    try {
      await axios.delete(`/api/sections/${id}`);
      setSections(sections.filter(s => s.id !== id));
      setQuestions(questions.filter(q => q.section_id !== id));
    } catch (error) {
      console.error('Error deleting section:', error);
    }
  };

  const deleteQuestion = async (id: number) => {
    try {
      await axios.delete(`/api/questions/${id}`);
      setQuestions(questions.filter(q => q.id !== id));
    } catch (error) {
      console.error('Error deleting question:', error);
    }
  };

  const onDragEnd = async (result: any) => {
    const { source, destination, type } = result;

  if (!destination) return;

  if (type === 'section') {
    if (source.index !== destination.index) {
      const newSections = Array.from(sections);
      const [reorderedSection] = newSections.splice(source.index, 1);
      newSections.splice(destination.index, 0, reorderedSection);

      setSections(newSections);

      // Update order_index for all sections
      const updatedSections = newSections.map((section, index) => ({
        ...section,
        order_index: index
      }));

      try {
        await Promise.all(updatedSections.map(section => 
          axios.put(`/api/sections/${section.id}`, { order_index: section.order_index })
        ));
      } catch (error) {
        console.error('Error updating section order:', error);
      }
    }
  } else {
  
      const sourceSectionId = parseInt(result.source.droppableId);
      const destinationSectionId = parseInt(result.destination.droppableId);
      const questionId = parseInt(result.draggableId);
      const newIndex = result.destination.index;
    
      if (sourceSectionId !== destinationSectionId || result.source.index !== result.destination.index) {
        try {
          const questionToMove = questions.find(q => q.id === questionId);
          if (questionToMove) {
            await axios.put(`/api/questions/${questionId}`, { 
              section_id: destinationSectionId,
              new_index: newIndex,
              text: questionToMove.text
            });
            // Update the state to reflect the new order
            setQuestions(prevQuestions => {
              const updatedQuestions = prevQuestions.filter(q => q.id !== questionId);
              updatedQuestions.splice(newIndex, 0, {...questionToMove, section_id: destinationSectionId});
              return updatedQuestions;
            });
          }
        } catch (error) {
          console.error('Error moving question:', error);
        }
      }
    }
  };

    return (
        <div className="p-4 md:p-16">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="sections" type="section">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {sections.map((section, index) => (
                      <Draggable key={section.id} draggableId={`section-${section.id}`} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className={`mb-8 bg-gray-100 p-4 rounded-lg ${
                              snapshot.isDragging ? 'opacity-50' : ''
                            }`}
                          >
                            <div className="flex flex-col w-full gap-2 mb-4">
                              <div className="flex flex-row items-center">
                                <div {...provided.dragHandleProps} className="mr-2 cursor-move">
                                  <Bars3Icon className="h-5 w-5 text-gray-400" />
                                </div>
                                <input
                                  type="text"
                                  placeholder='Section Name'
                                  value={section.name}
                                  onChange={(e) => {
                                    setSections(sections.map(s => s.id === section.id ? { ...s, name: e.target.value } : s));
                                    setChangedSectionData({ id: section.id, name: e.target.value });
                                  }}
                                  className="border rounded px-3 py-2 mr-3 flex-1"
                                />
                                <TrashIcon
                                  onClick={() => deleteSection(section.id)}
                                  className="h-5 w-5 text-red-500 hover:text-red-600 flex-none"
                                />
                              </div>
                              {!snapshot.isDragging && (
                                <>
                                  <textarea
                                    placeholder='Section Description'
                                    value={section.description}
                                    onChange={(e) => {
                                      setSections(sections.map(s => s.id === section.id ? { ...s, description: e.target.value } : s));
                                      setChangedSectionData({ id: section.id, description: e.target.value });
                                    }}
                                    className="border rounded px-3 py-2 mr-8 flex-1"
                                  />
                                  <Droppable droppableId={section.id.toString()} type="question">
                                    {(provided) => (
                                    <ul {...provided.droppableProps} ref={provided.innerRef} className="mb-4 pl-2 md:pl-4">
                                        {questions
                                        .filter(question => question.section_id === section.id)
                                        .map((question, index) => (
                                            <Draggable key={question.id} draggableId={question.id.toString()} index={index}>
                                            {(provided) => (
                                                <li
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                className="mb-2 flex items-center"
                                                >
                                                <div
                                                    {...provided.dragHandleProps}
                                                    className="mr-2 cursor-move"
                                                >
                                                    <Bars3Icon className="h-5 w-5 text-gray-400" />
                                                </div>
                                                <input
                                                    type="text"
                                                    value={question.text}
                                                    onChange={(e) => {
                                                        setQuestions(questions.map(q => q.id === question.id ? { ...q, text: e.target.value } : q));
                                                        setChangedQuestionText({ id: question.id, text: e.target.value });
                                                    }}
                                                    className="border rounded px-3 py-2 mr-2 flex-1"
                                                />
                                                <TrashIcon
                                                    onClick={() => deleteQuestion(question.id)}
                                                    className="h-5 w-5 text-gray-400 flex-none"
                                                >
                                                    
                                                </TrashIcon>
                                                </li>
                                            )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </ul>
                                    )}
                                </Droppable>
                                <div className="flex items-center">
                                    <input
                                    type="text"
                                    value={newQuestionTexts[section.id] || ''}
                                    onChange={(e) => setNewQuestionTexts({ ...newQuestionTexts, [section.id]: e.target.value })}
                                    placeholder="New Question Text"
                                    className="border rounded px-3 py-2 mr-2 flex-grow"
                                    />
                                    <PlusCircleIcon
                                    onClick={() => addQuestion(section.id)}
                                    className="h-5 w-5 text-green-500 hover:text-green-600"
                                    />
                                    
                                </div>
                                </>
                              )}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <div className="flex flex-row gap-2 mb-8">
                <input
                type="text"
                value={newSectionName}
                onChange={(e) => setNewSectionName(e.target.value)}
                placeholder="New Section Name"
                className="flex w-full md:w-auto border rounded px-3 py-2"
                />
                <button
                onClick={addSection}
                className="flex whitespace-nowrap bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                >
                Add Section
                </button>
            </div>
        </div>
    );
};

export default AuditTemplate;
