import React, { useState, useCallback } from 'react';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { processDataCleaners } from '../utils/dataProcessing.js';
import FileUpload from '../components/FileUpload.js';
import Report2FilterControls from '../components/Report2FilterControls.js';
import Report2Content from '../components/Report2Content.js';
import useFilteredEmployees from '../hooks/useFilteredEmployees.js';
import useSavedData from '../hooks/useSavedData.js';

Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

const Report2 = ({ data, setData }) => {
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [selectedEmployees, setSelectedEmployees] = useState([]);

    const filteredEmployees = useFilteredEmployees(data, selectedLocations);
    const savedDataHook = useSavedData(data, selectedLocations, selectedEmployees);

    const handleLocationChange = useCallback((e) => {
        const newSelectedLocations = Array.from(e.target.selectedOptions, (option) => option.value);
        setSelectedLocations(newSelectedLocations);
        setSelectedEmployees([]);
    }, []);

    const handleEmployeeChange = useCallback((e) => {
        const newSelectedEmployees = Array.from(e.target.selectedOptions, (option) => option.value);
        setSelectedEmployees(newSelectedEmployees);
    }, []);

    const options = {
        maxBarThickness: 80,
        maintainAspectRatio: false,
        scales: {
            y: { beginAtZero: true },
            x: { beginAtZero: true }
        },
        plugins: {
            legend: { display: true, position: 'bottom' },
            datalabels: {
                anchor: 'end',
                align: 'end',
                formatter: (value) => value,
                color: 'black',
                font: { size: 11 },
                offset: 0
            }
        }
    };

    return (
        <div>
            <div className="flex justify-center mb-8 space-x-4">
                <FileUpload setData={setData} processingFunction={processDataCleaners} reportType="report1and2" />
            </div>

            {data && data.locationStats && Object.keys(data.locationStats).length > 0 && (
                <Report2FilterControls
                    locations={Object.keys(data.locationStats)}
                    selectedLocations={selectedLocations}
                    handleLocationChange={handleLocationChange}
                    employees={filteredEmployees}
                    selectedEmployees={selectedEmployees}
                    handleEmployeeChange={handleEmployeeChange}
                />
            )}

            {(!data || !data.locationStats || Object.keys(data.locationStats).length === 0) && (
                <div className="flex justify-center mb-8 space-x-4">
                    <p>This report analyzes free pass user access from the Access log in Credlock. If it sees two cleaners arriving and departing, it will double the duration for that day.</p>
                </div>
            )}
            
            <Report2Content
                data={data}
                selectedLocations={selectedLocations}
                selectedEmployees={selectedEmployees}
                options={options}
                savedDataProps={{
                    ...savedDataHook,
                    handleSaveClick: savedDataHook.handleSaveClickWrapper,
                    handleLoadData: savedDataHook.handleLoadDataWrapper,
                    handleDeleteData: savedDataHook.handleDeleteDataWrapper,
                }}
            />

            
        </div>
    );
};

export default Report2;