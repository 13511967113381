import React from 'react';
import { Bar } from 'react-chartjs-2';
import { getUnifiedColor, calculateAverages } from '../utils/helpers.js';

const WeeklyAveragesChart = ({ data, selectedLocations, selectedEmployees, options }) => {
    const averages = calculateAverages(data, selectedLocations, selectedEmployees);
    
    const datasets = selectedLocations.length > 0 && averages[selectedLocations[0]]
        ? Object.keys(averages[selectedLocations[0]]).map(week => ({
            label: `Week ${week}`,
            data: selectedLocations.map(location => averages[location][week] || 0),
            backgroundColor: getUnifiedColor(`Week ${week}`)
        }))
        : [];
    
    const chartData = {
        labels: selectedLocations,
        datasets: datasets
    };

    return (
        <div>
            <h4 className="text-xl font-bold mb-8">Weekly Averages by Location</h4>
            <div className="chart-container" style={{ height: '400px' }}>
                <Bar data={chartData} options={options} />
            </div>
            <div className="table-container mt-8">
            <div className="overflow-x-auto custom-scroll">
                <table className="table-auto w-full text-left">
                    <thead>
                        <tr className='border-b border-gray-400'>
                            <th>Location</th>
                            {datasets.map((dataset, weekIdx) => (
                                <th className="pr-4 auto whitespace-nowrap" key={weekIdx}>{dataset.label}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {selectedLocations.map((location, locationIdx) => {
                            const locationData = datasets.map((dataset) => {
                                const weekData = dataset.data[locationIdx];
                                return weekData !== undefined ? weekData : 0;
                            });

                            return (
                                <tr key={locationIdx}>
                                    <td className="pr-8 pb-4 pt-2 w-auto whitespace-nowrap">{location}</td>
                                    {locationData.map((value, dataIdx) => (
                                        <td className="pr-8 pb-4 pt-2 w-auto whitespace-nowrap" key={dataIdx}>{value} min</td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            </div>
        </div>
    );
};

export default WeeklyAveragesChart;