import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import BarChart from './Report3BarChart.js';

const IncomePerArea = ({ areaSummary, selectedTime, years, selectedAreas }) => {
    //console.log('!!!IncomePerArea - Input:', { areaSummary, selectedTime, years, selectedAreas });

    const shouldRender = selectedAreas.length > 1 && (selectedTime === 'all' || selectedTime.length === 4);

    const chartData = useMemo(() => {
        if (!shouldRender) return [];

        const data = Object.values(areaSummary).map(area => {
            if (selectedTime === 'all') {
                const yearlyData = years.reduce((acc, year) => {
                    acc[year] = area.years[year].total;
                    return acc;
                }, {});
                return {
                    area: area.area,
                    ...yearlyData,
                    total: area.total
                };
            } else if (selectedTime.length === 4) {
                return {
                    area: area.area,
                    total_revenue: area.years[selectedTime].total
                };
            } else {
                // Return a default object for any other case
                return {
                    area: area.area,
                    total_revenue: 0
                };
            }
        }).filter(area => {
            if (selectedTime === 'all') {
                return years.some(year => area[year] > 0);
            } else {
                return area.total_revenue > 0;
            }
        });

        console.log('!!!IncomePerArea - Processed chart data:', data);
        return data.sort((a, b) => b.total_revenue - a.total_revenue);
    }, [areaSummary, selectedTime, shouldRender, years]);

    if (!shouldRender || chartData.length === 0) {
        //console.log('!!!IncomePerArea - Not rendering:', { shouldRender, chartDataLength: chartData.length });
        return null;
    }

    //console.log('!!!IncomePerArea - Rendering chart with data:', chartData);

    return (
        <div className="mb-8">
            <h2 className="text-xl font-bold mb-4">Income per Area</h2>
            <BarChart
                data={chartData}
                dataKey={selectedTime === 'all' ? null : "total_revenue"}
                xAxisDataKey="area"
                years={years}
                selectedTime={selectedTime}
            />
        </div>
    );
};

IncomePerArea.propTypes = {
    areaSummary: PropTypes.object.isRequired,
    selectedTime: PropTypes.string.isRequired,
    years: PropTypes.arrayOf(PropTypes.string).isRequired,
    selectedAreas: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default IncomePerArea;