import { generateReactHelpers } from "@uploadthing/react";
import type { OurFileRouter } from "./uploadthing";
import imageCompression from "browser-image-compression";

export const { useUploadThing, uploadFiles } = generateReactHelpers<OurFileRouter>();


export async function compressImage(file: File): Promise<File> {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };

  try {
    const compressedFile = await imageCompression(file, options);
    console.log("Compressed file:", compressedFile);
    return new File([compressedFile], file.name, { type: compressedFile.type });
  } catch (error) {
    console.error("Error compressing image:", error);
    throw error;
  }
}

export async function compressImages(files: File[]): Promise<File[]> {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };

  try {
    const compressedFiles = await Promise.all(
      files.map(async (file) => {
        const compressedFile = await imageCompression(file, options);
        return new File([compressedFile], file.name, { type: compressedFile.type });
      })
    );
    console.log("Compressed files:", compressedFiles);
    return compressedFiles;
  } catch (error) {
    console.error("Error compressing images:", error);
    throw error;
  }
}

export async function uploadImage(file: File): Promise<string> {
  try {
    console.log("Starting upload...");
    const [res] = await uploadFiles("imageUploader", {
      files: [file],
    });
    console.log("Upload response:", res);

    if (res && res.url) {
      return res.url;
    } else {
      throw new Error("File upload failed: No URL returned");
    }
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error;
  }
}

export async function uploadImages(files: File[]): Promise<string[]> {
  try {
    console.log("Starting upload of multiple images...");
    const res = await uploadFiles("imageUploader", {
      files: files,
    });
    console.log("Upload response:", res);

    if (res && res.length > 0) {
      return res.map(file => file.url).filter((url): url is string => url !== undefined);
    } else {
      throw new Error("File upload failed: No URLs returned");
    }
  } catch (error) {
    console.error("Error uploading images:", error);
    throw error;
  }
}